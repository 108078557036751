import { createSlice } from "@reduxjs/toolkit";
import { Paths } from "api/kyc/generated/client2";
import { userCreate } from "../asyncThunks/userCreate";
import { CaseReducerType } from "../../store";

export type UserResponse = Paths.RegisterUser.Responses.$201;

type ErrorWithStatusObject = {
	data: string;
	status: number;
};

type State = {
    new: UserResponse | ErrorWithStatusObject
};

const initialState: State = {
    new: ''
};

type CaseReducers<State> = {
    userCreateClear: CaseReducerType<State, object>;
};

export const userSlice = createSlice<State, CaseReducers<State>, string, {}>({
    name: "user",
    initialState,
    reducers: {
        userCreateClear: (state, { payload }) => {
            state.new = initialState.new
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userCreate.fulfilled, (state, action) => {
      	    state.new = action.payload
        });
	    builder.addCase(userCreate.rejected, (state, action) => {
		    //@ts-ignore
		    state.new = action.payload
        });
    },
});

export const { userCreateClear } = userSlice.actions;
export default userSlice.reducer;
