import { FC, ReactNode, useEffect, useState } from 'react'
import { Row, Col, Space } from 'antd'
import Text from 'antd/es/typography/Text'
import styled from 'styled-components'
import { useSelector } from 'hooks/useSelector'
import FormInput from 'modules/kyc-front/ui-elements/FormInput'
import { FormCheckboxRadio, FormCheckboxRadioStandalone } from 'modules/kyc-front/ui-elements/FormCheckboxRadio'
import ApproveInput from "modules/kyc-backend/ui-elements/Approve"
import { themeColor } from 'styles/themeStyles'
import _ from 'lodash'
import { descriptions } from './common'
import EditedInputInfo from '../ui-elements/EditedInput'

const CheckboxWrapperStyles = styled.div`
    label {
        margin-bottom: 15px;
        line-height: 20px;
        gap: 25px;
    }
    input {
        position: relative;
        top: 3px;
    }
`
const ErrorContainer = styled.div`
    color: ${themeColor.red};
`

interface CProps {
    error?: string,
    children?: ReactNode
}

const CheckboxWrapper: FC<CProps> = ({error, children}) => {
    return (
        <CheckboxWrapperStyles className='checkbox-wrapper'>
            {children}
            {error && error !== '' &&
                <ErrorContainer>{error}</ErrorContainer>
            }
        </CheckboxWrapperStyles>
    )
}

const DeclarationWrapper = styled.div`
    margin-top: 20px;
`


interface KProps {
    values: any,
    errors: any,
    handleInputChange: any,
    disabled?: boolean,
    printRender?: boolean,
    person?: any,
    type: 'individual' | 'authorized-persons' | 'ubos',
    uboType?: boolean
}

const KycSourceOfWealthForm: FC<KProps> = ({values, errors, handleInputChange, disabled, printRender, person, uboType, type}) => {
    const envs = useSelector((state) => state.general2.envs);   
    const personIsArchived = person?.archivedAt ? true : false
    const {sourceOfWealthBusinessActivities, sourceOfWealthInheritance, sourceOfWealthOther, sourceOfWealthProfessionalOccupation} = values
    let [isSourceError, setIsSourceError] = useState(false)
    const onFocusOut = () => {
        if(sourceOfWealthBusinessActivities === true || sourceOfWealthInheritance === true || sourceOfWealthOther === true || sourceOfWealthProfessionalOccupation === true) {
            setIsSourceError(false)
        } else {
            setIsSourceError(true)
        }
        
    }
    useEffect(() => {
        if(!_.isEmpty(errors)) {
            if(sourceOfWealthBusinessActivities === true || sourceOfWealthInheritance === true || sourceOfWealthOther === true || sourceOfWealthProfessionalOccupation === true) {
                setIsSourceError(false)
            } else {
                setIsSourceError(true)
            }
        }
        
    }, [errors]);
    const roleOptions = [{
        label: descriptions.roleTypeHoldsMoreThan25PercentageOfShares,
        value: 'HoldsMoreThan25PercentageOfShares'
        },
        {
            label: descriptions.roleTypeUltimatelyControlOverBusiness,
            value: 'UltimatelyControlOverBusiness'
        },
        {
            label: descriptions.roleTypeMemberOfGoverningBody,
            value: 'MemberOfGoverningBody'
        }
    ]
    return (
        <Space direction='vertical' size={'large'}>
            {uboType ? (
                <DeclarationWrapper className='print10'>
                <Row justify="space-between">
                    <Col>
                        <Text><b>1. Beneficial Owners</b>*</Text>
                    </Col>
                    <Col>
                        <Row>
                            {envs.admin && !personIsArchived &&
                                <ApproveInput name='roleType' type='ubos' personId={person.beneficialOwnerId} />
                            }
                            <EditedInputInfo name='roleType' type={type} options={roleOptions} />
                        </Row>
                    </Col>
                </Row>
                <Text>{descriptions.beneficialOwnersUbo1} <b>{descriptions.beneficialOwnersUbo2}</b></Text>
                <CheckboxWrapper error={errors?.roleType}>
                    <FormCheckboxRadio id='roleType' type='radio' onChange={handleInputChange}
                        options={roleOptions} disabled={disabled} showApprove={false} error={errors?.roleType} approveType={type} />
                </CheckboxWrapper>
            </DeclarationWrapper>
            ) : (
                <div>
                    <Text><b>1. Beneficial Owners</b></Text><br/>
                    <Text>{descriptions.beneficialOwners}</Text>
                </div>
            )}
            <div>
                <Row justify="space-between">
                    <Col>
                        <Text><b>2. Source of Wealth</b></Text>
                    </Col>
                    <Col>
                        <Row>
                            {envs.admin &&
                                <ApproveInput name='sourceOfWealthBusinessActivities' type={type} />
                            }
                            <EditedInputInfo name='sourceOfWealthBusinessActivities' type={type} />
                        </Row>
                    </Col>
                </Row>
                <Text>{uboType ? descriptions.sourceOfWealthUbo : descriptions.sourceOfWealth}:</Text>
            </div>
            <CheckboxWrapper error={errors?.sourceOfWealth}>
                <FormCheckboxRadioStandalone id='sourceOfWealthBusinessActivities' type='checkbox' onChange={handleInputChange} onBlur={onFocusOut} label='Business activities (please name company name and business sector below)' disabled={disabled} error={errors?.sourceOfWealthBusinessActivities} groupError={isSourceError}  />
                <FormCheckboxRadioStandalone id='sourceOfWealthInheritance' type='checkbox' onChange={handleInputChange} onBlur={onFocusOut} label='Inheritance (please name testator and relationship below)' disabled={disabled} error={errors?.sourceOfWealthInheritance} groupError={isSourceError} />
                <FormCheckboxRadioStandalone id='sourceOfWealthProfessionalOccupation' type='checkbox' onChange={handleInputChange} onBlur={onFocusOut} label='Professional occupation (please name employer and occupation below)' disabled={disabled} error={errors?.sourceOfWealthProfessionalOccupation} groupError={isSourceError} />
                <FormCheckboxRadioStandalone id='sourceOfWealthOther' type='checkbox' onChange={handleInputChange} onBlur={onFocusOut} label='Other (please provide details below)' disabled={disabled} error={errors?.sourceOfWealthOther} groupError={isSourceError} />
            </CheckboxWrapper>
            <FormInput label='Please describe the source of wealth: *' subLabel='(in 2-3 sentences)' className='sowIndividualPrint' subLabelClass='sowDescriptionIndividualPrint' name='sourceOfWealthDescription' component='textarea' onChange={handleInputChange} value={values.sourceOfWealthDescription} disabled={disabled} printRender={printRender} error={errors?.sourceOfWealthDescription} type={type} />
            <FormCheckboxRadio label='Total estimated net worth ( EUROs )' subLabel='(properties, cash, equity, etc.)' id='estimatedNetWorth' type='radio' onChange={handleInputChange}
                options={[{
                        label: '< 100,000',
                        value: '0'
                    },
                    {
                        label: '100,000 - 1 milion',
                        value: '100000'
                    },
                    {
                        label: '1 - 5 milion',
                        value: '1000001'
                    },
                    {
                        label: '> 5 milion',
                        value: '5000001'
                    }
                ]
            } disabled={disabled} className="netWorthPrintIndividual" error={errors?.estimatedNetWorth} approveType={type} />
            {uboType ? (
                <></>
            ) : (
                <>
                    <label><b>3. {descriptions.estimatedExchangeAmountFuture}:</b></label>
                    <Row gutter={30}>
                        <Col md={12}>
                            <FormInput label='Amount (EUR)' name='estimatedAmountOfEUR' error={errors?.estimatedAmountOfEUR} onChange={handleInputChange} value={values.estimatedAmountOfEUR} disabled={disabled} printRender={printRender} type='individual' formatting='numbers' />
                        </Col>
                    </Row>
                </>
            )}
            <div>
                <Text><b>{uboType ? '3.' : '4.'} Tax Compliance</b></Text><br/>
                <Text>{descriptions.taxCompliance}</Text>
            </div>
        </Space>
        )

}

export default KycSourceOfWealthForm