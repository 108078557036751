import { useNavigate, useParams } from 'react-router-dom'
import { dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import { FC, useEffect, useState } from 'react'
import { useActionStatus } from '../../../../hooks/useActionStatus'
import { businessProfilesGet } from 'store/kyc-backend/asyncThunks/businessProfilesGet'
import { NavItem } from 'modules/kyc-front/kyc-layout/KycLayout'
import BusinessProfileLayout from './BusinessProfileLayout'
import { Profile } from './profile.guard'
import { businessProfileUpdate } from 'store/kyc-backend/asyncThunks/businessProfileUpdate'
import { businessProfileUpdateClear } from 'store/kyc-backend/slices/businessProfileSlice'
import { App } from 'antd'

interface BPContainerProps {
    hasId?: boolean,
}

export const findDefaultProfile = (businessProfiles: Profile[]) => {
    const approvedProfile = businessProfiles.find((elem: any) => elem?.status === 'APPROVED');
    return approvedProfile ? approvedProfile : businessProfiles[0]; 
}

const BusinessProfileContainer: FC<BPContainerProps> = ({hasId}) => {
	const navigate = useNavigate();
	const envs = useSelector((state) => state.general2.envs);
	const { businessProfiles } = useSelector((state) => state.admin2.businessProfiles);
    const { businessProfileId } = useParams()
	const [dataLoaded, setDataLoaded] = useState(false);
    const businessProfilesGettingStatus = useActionStatus(businessProfilesGet)
    const businessProfilesSavingStatus = useActionStatus(businessProfileUpdate)
    const { message } = App.useApp();
	useEffect(() => {
		callInitialFunctions();
    }, [envs.profileId]);
    useEffect(() => {
        if(businessProfilesGettingStatus.isError) {
            message.error('Some error occured while getting this element')
        }
        if(businessProfilesGettingStatus.isFullfilled && dataLoaded === false) {
            if(hasId) {
                const bProfile = businessProfiles.find((elem: any) => elem?.id === businessProfileId);
            } else {
                const defaultProfile = findDefaultProfile(businessProfiles)
                if(defaultProfile?.id) {
                    navigate(envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/business-profile/' + defaultProfile.id);
                }
            }
            setDataLoaded(true);
        }
    }, [businessProfilesGettingStatus])
    useEffect(() => {
        if(businessProfilesSavingStatus.isError) {
            message.error('Some error occured while saving this form')
        }
        if(businessProfilesSavingStatus.isFullfilled) {
            message.success('Form saved successfully')
            dispatch(businessProfileUpdateClear({}))
        }
    }, [businessProfilesSavingStatus])
    const callInitialFunctions = () => {
    	if(dataLoaded === false && envs.profileId !== '') {
            dispatch(businessProfilesGet({params: {clientId: envs.profileId}}))
		}
    }
	return (dataLoaded ? <BusinessProfileLayout businessProfileId={businessProfileId} /> : <></>);
}

export default BusinessProfileContainer
