import { createSlice } from '@reduxjs/toolkit'
import { setStepsScheme } from 'store/kyc-front/slices/kycApplicationSlice'
import { CaseReducerType } from 'store/store'
import { APSteps, UboSteps, Step } from 'stepsScheme'
import { kycApplicationChangesGet } from '../asyncThunks/kycApplicationChangesGet'
import { Components } from "api/kyc/generated/client2"
import { kycApplicationChangesDiscard } from '../asyncThunks/kycApplicationChangesDiscard'

type SectionChange = {
    ref: string
    slug: string
    changeType: string
    previousValue: string
}

interface DocValidationError {
    approvals: [{ref: string, slug: string, isApprovalRequirementSatisfied: boolean, approvedBy: string[]}],
    id: string
}

interface StepChanges {
	step: number
    substep?: number
    link: string
    name: string
	changesInStep: SectionChange[]
    docChangesInStep: DocValidationError[]
    refId?: string,
    changeType?: string
}

const parseChanges = (steps: any, elem: any) => {
    let payloadEl: StepChanges[] = []
    let data = elem?.payload
    steps?.forEach((step: any) => {
        const isAp = step.type == 'authorizedPersons'
        const isUbo = step.type == 'beneficialOwners'
        const stepFields = isAp ? APSteps.flatMap(step => step.fields) : isUbo ? UboSteps.flatMap(step => step.fields) : step.fields || []
        const personsRefList = step.ref === 'ap' ? data?.authorizedPersons : step.ref === 'ubo' ? data?.beneficialOwners : data
        const refList = step.refId && personsRefList?.find((el: any) => el.id === step.refId)
        const validationErrosList = step.refId ? refList : step.type && data ? data[step.type] : data
        const validationDocsList = step.refId ? refList?.documents : data.documents

        let changesInStep: SectionChange[] = validationErrosList?.changes?.filter((change: SectionChange) => {
            if (change.slug === 'nationality') {
                if(change.ref === '' && step.fields?.includes('identityDocument.nationality')) {
                    return false
                }
            }
            if (change.ref !== '') {
                return stepFields.includes(change.ref + '.' + change.slug)
            } else {
                return stepFields.includes(change.slug)
            }
        }) || []

        let docChangesInStep: DocValidationError[] = validationDocsList?.filter((change: SectionChange) => {
            return (
                change.ref === 'docs' &&
                stepFields.includes(change.ref + '.' + change.slug)
            )
        }) || []

        if ((isAp || isUbo) && (refList?.documents?.length === 0 || !refList?.documents)) {
            docChangesInStep = []
        }

        payloadEl.push({
            step: step?.step,
            substep: step?.substep,
            link: step.link,
            name: step.name,
            changesInStep: changesInStep,
            docChangesInStep: docChangesInStep,
            refId: step.refId,
            changeType: step?.hasSubsteps && validationErrosList ? validationErrosList?.changeType : undefined
        })
        
    })
    return payloadEl
}

type State = {
    steps: Step[]
    applicationChanges: any
    applicationChangesByStep: any[]
    changesDiscarded: any
}

type CaseReducers<State> = {
    setStepsClear: CaseReducerType<State, Step[]>;
    kycApplicationChangesDiscardClear: CaseReducerType<State, any>;
    kycApplicationChangesGetClear: CaseReducerType<State, any>;
}

const initialState: State = {
    steps: [],
    applicationChanges: {},
    applicationChangesByStep: [],
    changesDiscarded: false
}

export const kycApplicationChangesSlice =  createSlice<State, CaseReducers<State>, string, {}>({
  name: 'kycApplicationChanges',
  initialState,
  reducers: {
    setStepsClear: (state) => {
      state.steps = initialState.steps
    },
    kycApplicationChangesDiscardClear: (state) => {
        state.changesDiscarded = false
    },
    kycApplicationChangesGetClear: (state) => {
        state.applicationChanges = initialState.applicationChanges
        state.applicationChangesByStep = initialState.applicationChangesByStep
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setStepsScheme, (state, action) => {
        state.steps = action.payload;
    });
    builder.addCase(kycApplicationChangesGet.fulfilled, (state, action) => {
        state.applicationChanges = action.payload
        state.applicationChangesByStep = parseChanges(state.steps, action)
    })
    builder.addCase(kycApplicationChangesGet.rejected, (state, action) => {
        state.applicationChanges = initialState.applicationChanges
        state.applicationChangesByStep = initialState.applicationChangesByStep
    })
    builder.addCase(kycApplicationChangesDiscard.fulfilled, (state, action) => {
        state.changesDiscarded = action.payload
    })
  },
});

export const { setStepsClear, kycApplicationChangesDiscardClear, kycApplicationChangesGetClear } = kycApplicationChangesSlice.actions;
export default kycApplicationChangesSlice.reducer;
