import { createSlice } from "@reduxjs/toolkit";
import { CaseReducerType } from "../../store";
import { Components } from "api/kyc/generated/client2";
import { notesGet } from "../asyncThunks/notesGet";
import { noteCreate } from "../asyncThunks/noteCreate";

export type NotesResponse = Components.Schemas.Note

type State = {
  notes: NotesResponse[],
  newNote: boolean
};

const initialState: State = {
  notes: [],
  newNote: false
};

type CaseReducers<State> = {
	noteCreateClear: CaseReducerType<State, object>;
  	notesGetClear: CaseReducerType<State, object>;
};

export const notesSlice = createSlice<State, CaseReducers<State>, string, {}>({
  name: "notes",
  initialState,
  reducers: {
	notesGetClear: (state, { payload }) => {
		state.notes = initialState.notes
	},
	noteCreateClear: (state, { payload }) => {
		state.newNote = initialState.newNote
	},
  },
  extraReducers: (builder) => {
	builder.addCase(notesGet.fulfilled, (state, action) => {
		state.notes = action.payload;
  	});
	builder.addCase(noteCreate.fulfilled, (state, action) => {
		state.newNote = action.payload === ''
  	});
  },
});

export const { noteCreateClear, notesGetClear } = notesSlice.actions;
export default notesSlice.reducer;
