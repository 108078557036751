import { createSlice } from "@reduxjs/toolkit";
import { CaseReducerType } from "../../store";

type State = {
    isForbidden: boolean;
};

const initialState: State = {
    isForbidden: false,
};

type CaseReducers<State> = {
    setForbidden: CaseReducerType<State, boolean>;
};

export const forbiddenSlice = createSlice<State, CaseReducers<State>, string, {}>({
    name: "forbidden",
    initialState,
    reducers: {
        setForbidden: (state, { payload }) => {
            state.isForbidden = payload;
        },
    },
});

export const { setForbidden } = forbiddenSlice.actions;

export default forbiddenSlice.reducer;
