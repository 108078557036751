import { ExclamationCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Modal } from "antd"
import { FC, ReactNode } from 'react'
import styled from "styled-components"
import Title from 'antd/es/typography/Title'
import { themeColor } from "styles/themeStyles"


const ModalCustom = styled(Modal)`
	.ant-btn.warning {
		background: ${themeColor.orangeWarning};
		&:hover,
		&:focus,
		&:active {
			background: ${themeColor.orangeWarningDarker};
		}
	}
`

type DProps = {
	open: boolean,
	handleOk: (value: any) => void,
	handleCancel: (value: any) => void,
	name: string,
	okText?: string,
	cancelText?: string
	children?: ReactNode
}

const ActionModalComponent: FC<DProps> = ({open, handleOk, handleCancel, name, okText = 'Confirm', cancelText = 'Cancel', children}) => (
	<ModalCustom
		title=""
		open={open}
		onOk={handleOk}
		okText={okText}
		okButtonProps={{className: 'warning'}}
		onCancel={handleCancel}
		cancelText={cancelText}
	>
		<div style={{textAlign: 'center'}}>
			<QuestionCircleOutlined style={{color: themeColor.orangeWarning, fontSize: 100, marginTop: 20, marginBottom: 30}} />
			<br />
			<Title level={3}>{name}</Title>
			{children}
			<br />
			<br />
		</div>
  </ModalCustom>
)
export default ActionModalComponent
