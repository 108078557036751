import moment from 'moment/moment'
import { FC, useState } from 'react'
import styled from 'styled-components'
import { BusinessProfileResponse } from 'store/kyc-backend/asyncThunks/businessProfilesGet'
import { useSelector } from '../../../../../hooks/useSelector'
import PdfWrapper from '../../../../../pdf/PdfWrapper'
import { downloadFile } from '../../../../../utilities/downloadFile'
import { ButtonUpper } from '../../../../kyc-front/ui-elements/Buttons'
import { dateFormatDetailed } from '../../../../kyc-front/ui-elements/FormDate'
import { BpPdfContent } from './BpPdfData'

const Wrapper = styled.div``

interface BProps {
	data?: BusinessProfileResponse,
}
export const BpGetPdfBtn: FC<BProps> = ({data}) => {
	const { accountType } = useSelector((state) => state.general2.envs)
	const { profiles } = useSelector((state) => state.admin2.admin)
	const [showPdf, setShowPdf] = useState<boolean>(false)
	const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)
	
	const onBtnClick = () => {
		setShowPdf(true)
		setIsBtnLoading(true)
	}
	const onReady = (url: string | null) => {
		setIsBtnLoading(false)
		if (url) {
			setShowPdf(false)
			downloadFile(url, `BP-${data?.id}.pdf`)
		}
	}
	
	
	return (
		<Wrapper>
			<ButtonUpper type={'primary'} onClick={onBtnClick} loading={isBtnLoading} style={{ width: 330 }}>Download PDF</ButtonUpper>
			{showPdf && data && <PdfWrapper onUrl={onReady} title={`Business Profile`} generatedTime={moment(new Date()).format(dateFormatDetailed).toString()}>
				<BpPdfContent profile={data} isCompanyType={accountType === 'BUSINESS'} adminProfiles={profiles}/>
			</PdfWrapper>}
		</Wrapper>
	)
}
