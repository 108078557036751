import { Space, Row, Col, Layout, Badge, Menu, Dropdown, App, Tooltip } from 'antd'
import type { MenuProps } from 'antd'
import _ from 'lodash'
import Title from 'antd/es/typography/Title'
import Text from 'antd/es/typography/Text'
import { LanguagePicker } from 'modules/kyc-front/ui-elements/LanguagePicker'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { logout, logoutUser } from 'modules/keycloak'
import { setEditedFormConfirmed, setNav } from 'store/kyc-front/slices/navSlice'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import { kycApplicationValidateClear } from 'store/kyc-front/slices/kycApplicationSlice'
import { adminMessagesGet } from 'store/kyc-backend/asyncThunks/adminMessagesGet'
import { useSelector } from 'hooks/useSelector'
import { dispatch } from 'store/store'
import { Components } from 'api/kyc/generated/client'
import { PageContent } from 'modules/layout/PageContent'
import { ButtonUpper } from 'modules/kyc-front/ui-elements/Buttons'
import { adminProfileGet } from 'store/kyc-backend/asyncThunks/adminProfileGet'
import { adminProfilesGet } from 'store/kyc-backend/asyncThunks/adminProfilesGet'
import { adminProfileGetClear } from 'store/kyc-backend/slices/adminSlice'
import { identityGetClear } from 'store/kyc-front/slices/identitySlice'
import { useActionStatus } from 'hooks/useActionStatus'
import { langKey } from 'utilities/i18n/i18n'
import { camelCaseToName } from 'utilities/nameParsers'
import Error403 from 'modules/kyc-front/Error403'
import FooterDrawer from 'modules/kyc-front/kyc-layout/KycFooterDrawer'
import { KycSteps } from 'modules/kyc-front/kyc-layout/KycSteps'
import KycMessages from 'modules/kyc-messages/KycMessages'
import Logo from 'assets/logo-kyc.png'
import { ADMIN_BASE_PATH, BASE_PATH } from '../../../routes/AppRoutes'

import { FormSaver } from 'utilities/FormSaver'

import { KycNav } from 'modules/kyc-front/kyc-layout/KycNav'
import { themeColor, themeSize, themeScreen } from 'styles/themeStyles'
import { ENV } from "index"
import { NavLink } from 'react-router-dom'
import { kycApplicationChangesGetClear } from 'store/kyc-front/slices/kycApplicationChangesSlice'
import { signedDocsGetClear } from 'store/kyc-backend/slices/signedDocsSlice'
import { clientGetClear } from 'store/kyc-backend/slices/clientsSlice'
const { Footer, Header } = Layout


const PageName = styled(Text)`
	color: ${themeColor.primaryColor};
	font-size: ${themeSize.heading3};
	font-weight: 500;
`
const Sticky = styled.div`
	position: -webkit-sticky;
  	position: sticky;
  	z-index: 9;
  	&.no-stick {
  		position: relative;
  	}
`
const Fixed = styled.div`
  	position: fixed;
  	z-index: 10;
	width: 100%;
	.content {
		max-width: ${themeScreen.maxContent};
	}
  	&.no-stick {
  		position: relative;
		bottom: 0!important;
  	}
`
export const ImgLogo = styled.img`
	max-width: 265px;
	margin-left: -45px;
`

export const HeaderCustom = styled(Header)`
	position: fixed;
	position: sticky;
	z-index: 99;
	width: 100%;
	top: 0;
	border-bottom: 1px solid ${themeColor.grayWhite};
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent};
	}
`
export const FooterCustom = styled(Footer)`
	width: 100%;
	border-top: 1px solid ${themeColor.grayWhite};
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent};
	}
`

const FooterCustomModule = styled(Footer)`
	padding: 0;
	max-width: ${themeScreen.maxContent};
`

export const NavCustom = styled.div`
	border-bottom: 1px solid ${themeColor.grayWhite};
	position: fixed;
	position: sticky;
	z-index: 99;
	width: 100%;
	top: 65px;
	background-color: #fff;
	padding-top: 0;
	padding-bottom: 0;
	.content {
		margin: auto;
		max-width: ${themeScreen.maxContent};
	}
	p {
		margin-bottom: 0;
		padding-top: 3px;
		color: ${themeColor.gray};
		.client {
			color: #000;
		}
	}
`

export const NavCustomTabs = styled(NavCustom)`
	top: 108px;
	padding-top: 0;
	padding-bottom: 0;
`

export const ButtonUpperCustom = styled.span`
	.btn-sm {
		font-size: 14px;
		font-weight: 500;
		padding-left: 0;
		color: ${themeColor.gray};
	}
`

const ButtonUpperTabs = styled(ButtonUpper)`
	&.btn-sm {
		color: ${themeColor.gray};
		padding-top: 8px;
		padding-bottom: 8px;
		&.active {
			color: ${themeColor.primaryColor};
			background-color: ${themeColor.grayWhite};
		}
	}
`

type ReviewStatusType = Components.Schemas.ReviewStatusType

interface IProps {
	approve?: boolean,
	listingView?: boolean,
	pageName?: string,
	heading?: string,
	adminView?: boolean,
	children?: ReactNode,
	validationAction?: (value: any) => void,
	validationActionParams?: object,
	hideValidate?: boolean,
	hideSaveButton?: boolean,
	hideApproveStepButton?: boolean,
	setErrorsListing?: (value: any) => any,
	nav: {
		next?: string,
		step?: number,
		substep?: number,
		refId?: string,
		nextName?: string,
		nextButton?: string,
		prev?: string,
		prevName?: string,
		status?: ReviewStatusType | undefined,
		hideNav?: boolean,
		hideSteps?: boolean,
		navPage?: NavItem,
		navSubpage?: string,
		link?: string
	}
}


const MenuWrapper = styled.div`
	.ant-menu{
		position: relative;
		top: -1px;
		&.ant-menu-dark {
			background: #fff;
			.ant-menu-item {
				padding: 0;
				a {
					padding: 0 16px;
					display: block;
					color: #000;
					font-weight: bold;
					&.active {
						background-color: #f6f6f6;
						color: ${themeColor.primaryColor};
					}
					&:hover:not(.active) {
						background-color: #f6f6f6;
						color: #000;
					}
				}
			} 
		}
	}
	
	
`

export enum NavItem {
	// admin
	ACTION_POINTS = 'action-points',
	CLIENT_PROFILES = 'client-profiles',
	ARCHIVED = 'archived',
	MESSAGES = 'messages',
	
	// client
	CLIENT_PROFILE = 'profile',
	CLIENT_INBOX = 'inbox'
}

const items = (count: number, isAdmin?: boolean) => {
	if(isAdmin) {
		return ([
			{
				label: <NavLink to={`${ADMIN_BASE_PATH}/${NavItem.ACTION_POINTS}`} className={({isActive}) => isActive ? "active" : ""}>Action points</NavLink>,
				key: NavItem.ACTION_POINTS,
			},
			{
				label: <NavLink to={`${ADMIN_BASE_PATH}/${NavItem.CLIENT_PROFILES}`}>Client profiles</NavLink>,
				key: NavItem.CLIENT_PROFILES,
			},
			{
				label: <NavLink to={`${ADMIN_BASE_PATH}/${NavItem.MESSAGES}`}>Messages <Badge count={count} /></NavLink>,
				key: NavItem.MESSAGES
			}
		])
	} else {
		return ([
			{
				label: <NavLink to={`${BASE_PATH}/${NavItem.CLIENT_PROFILE}`}>KYC Profile</NavLink>,
				key: NavItem.CLIENT_PROFILE,
			},
			{
				label: <NavLink to={`${BASE_PATH}/${NavItem.CLIENT_INBOX}`}>Messages <Badge count={count} /></NavLink>,
				key: NavItem.CLIENT_INBOX
			}
		])
	}
}

export const KycLayout: FC<IProps> = ({
	pageName, heading, adminView, children, nav, validationAction, validationActionParams, approve, listingView, hideValidate, hideSaveButton, hideApproveStepButton, setErrorsListing
}) => {
	let {next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, hideSteps, navPage, navSubpage, link} = nav
	const { t } = useTranslation()
	const envs = useSelector((state) => state.general2.envs)
	const {kycApplication} = useSelector((state) => state.user2.kycApplication)
	const {profileMessages} = useSelector((state) => state.user2.messages)
	const {apiURL, admin, adminSuffix, type, env, routeSuffix, clientId} = envs
	const adminProfile = useSelector((state) => state.admin2.admin)
	const adminInbox = useSelector((state) => state.admin2.adminInbox)
	const { clientSummary } = useSelector((state) => state.admin2.clients)
	const navEl = useSelector((state) => state.general2.nav)
	const { session } = useSelector((state) => state.general2.auth)
	const adminProfileLoaded = useActionStatus(adminProfileGet)
   	const [navSearch, setNavSearch] = useState('')
   	const statusInfo = kycApplication.status
	const client = clientSummary.length ? clientSummary[0] : undefined
   	const relationStatus = client ? client.status === 'VERIFIED' ? true : false : false
   	const adminMessagesGetStatus = useActionStatus(adminMessagesGet)
	const getAdminProfilesStatus = useActionStatus(adminProfilesGet)
	const { message } = App.useApp()
   	let messagesCount = 0
   	if(adminInbox?.messages?.length) {
		messagesCount = adminInbox.messages.filter((messageEl) => !messageEl.viewedByAdmin && !messageEl.createdBy).length
   	}
	if(!envs.admin && profileMessages?.length) {
		messagesCount = profileMessages.filter(messageEl => !messageEl.viewedAt && messageEl.createdBy).length
   	}
   	const onClick: MenuProps['onClick'] = e => {
	    dispatch(setNav({next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, navPage: e.key, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage, link: link, activeListingTab: navEl.nav.activeListingTab}))
		dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: '/' + e.key, general: true, showErrors: false, refId, skipNavigate: true}))
	}
	useEffect(() => {
		dispatch(setNav({next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, navPage: nav.navPage ? nav.navPage : navEl.nav.navPage, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage, link: link, activeListingTab: navEl.nav.activeListingTab, query: navEl.nav.query}))
		if(envs.admin && type === 'standalone') {
			if(!adminMessagesGetStatus.isFullfilled) {
				//dispatch(adminMessagesGet({params: {}}))
			}
			const interval = setInterval(() => {
				dispatch(adminMessagesGet({params: {}}))
			}, 60000)
			return () => clearInterval(interval)
		}
	}, [])
	useEffect(() => {
		dispatch(setNav({next, step, substep, refId, nextName, nextButton, prev, prevName, status, hideNav, navPage: navPage ? navPage : navEl.nav.navPage, paginationPage: navEl.nav.paginationPage, sorters: navEl.nav.sorters, filters: navEl.nav.filters, navSubpage, link: link, activeListingTab: navEl.nav.activeListingTab, query: navEl.nav.query}))
	}, [status, prev, next, step, navPage, status])
	useEffect(() => {
		if(envs.admin) {
			if(adminProfile.profile.id === '') {
				dispatch(adminProfileGet({params: null}))
			}
			if(adminProfile.profiles.length === 0 && !getAdminProfilesStatus.isFullfilled) {
				dispatch(adminProfilesGet({params: {type: 'ADMIN'}}))
			}
		}
    }, [adminProfile])
	
    useEffect(() => {
    	let navSearchNew = navEl.nav.paginationPage ? '?page=' + navEl.nav.paginationPage : ''
	    if(navEl.nav.sorters) {
	    	_.forEach(navEl.nav.sorters, (val, name) => {
	    		if(val) {
	    			let prefix = navSearchNew !== '' ? '&' : '?'
	    			navSearchNew += prefix + name + '=' + val
	    		}
	    	})
	    }
	    if(navEl.nav.filters) {
	    	_.forEach(navEl.nav.filters, (val, name) => {
	    		if(val) {
	    			let prefix = navSearchNew !== '' ? '&' : '?'
	    			navSearchNew += prefix + name + '=' + val
	    		}
	    	})
	    }
		if(navEl.nav.query) {
			let prefix = navSearchNew !== '' ? '&' : '?'
	    	navSearchNew += prefix + 'query' + '=' + navEl.nav.query
		}
	    setNavSearch(navSearchNew)
    }, [navEl.nav])
	const handleLogoutClick = () => {
		if(envs.isBusiness) {
			if(envs.admin) {
				dispatch(adminProfileGetClear({}))
				dispatch(signedDocsGetClear({}))
			} else {
				dispatch(identityGetClear({}))
			}
			
		} else {
			if(envs.admin) {
				dispatch(adminProfileGetClear({}))
				dispatch(signedDocsGetClear({}))
			} else {
				dispatch(identityGetClear({}))
			}
		}
		dispatch(kycApplicationValidateClear({}))
		dispatch(kycApplicationChangesGetClear({}))
		dispatch(setEnvs({apiURL, admin, adminSuffix, type, env, routeSuffix, profileId: '', clientId: '', accountType: ''}))
		dispatch(clientGetClear({}))
		dispatch(identityGetClear({}))
		onLogout()
	}
	const onLogout = async () => {
		await logoutUser({
			refreshToken: session?.refresh_token,
			keycloakDomain: ENV.KEYCLOAK_DOMAIN,
			client_id: envs.admin ? ENV.KEYCLOAK_CLIENT_BACK : ENV.KEYCLOAK_CLIENT_FRONT,
			//@ts-ignore
			is_backend: envs.admin,
			callback: () => dispatch(logout(true))
		})
	}
	
	const confirm = (link: string, general?: boolean) => {
        dispatch(setEditedFormConfirmed({onConfirm: true, onConfirmLink: link === '' ? '/' : link, general, showErrors: false, refId }))
    }
	const capitalize = (s?: string) => (s && s[0].toUpperCase() + s.slice(1).toLocaleLowerCase()) || ""
	
	const onCodeMenuClick: MenuProps['onClick'] = (e) => {
		//@ts-ignore
		codeCopy(e.domEvent.target.textContent);
	}
	const onCodeClick = (e: React.MouseEvent) => {
		//@ts-ignore
		codeCopy(e.target.textContent)
	}
	const codeCopy = (code?: string) => {
		if(code) {
			navigator.clipboard.writeText(code)
			message.success(`Client's code was copied successfully`)
		}
	}
	
	return (
		<>
			{envs.type === 'standalone' &&
				<HeaderCustom>
					<div className='content'>
						<Row>
							<Col sm={8} md={7} lg={6} xl={5}>
								<ImgLogo src={Logo} alt='Bitclear KYC' />
							</Col>
							<Col sm={8} md={7} lg={10} xl={11}>
								{envs.type === 'standalone' &&
									<MenuWrapper><Menu theme={'dark'} style={{ width: 510 }} onClick={onClick} mode="horizontal" items={items(messagesCount, envs.admin)} /></MenuWrapper>
								}
							</Col>
							<Col sm={8} md={10} lg={8} xl={8}>
								<div style={{color: themeColor.gray, textAlign: 'right'}}>
									<span style={{color: '#000'}}>{t(langKey('hello'))}, {envs.admin ? camelCaseToName(adminProfile.profile?.name) : envs.accountType === 'BUSINESS' ? kycApplication.values.name : kycApplication.values.firstName ? kycApplication.values.firstName : kycApplication.values.lastName ? kycApplication.values.lastName : 'there'}</span>									
									<span> | </span>
									<LanguagePicker/>
									<span> | </span>
									<a onClick={handleLogoutClick}><b>{t(langKey('logout'))}</b></a>
								</div>
							</Col>
							
						</Row>
					</div>
				</HeaderCustom>
			}
			{envs.admin && 
				<KycMessages />
			}
			{envs.admin && nav.step !== -1 &&
				<>
					<NavCustom style={{top: messagesCount ? 122 : 65}}>
						<div className='content'>
							<Row justify='space-between'>
								<Col>
									<ButtonUpperTabs onClick={() => confirm('/summary')}  className={navEl.nav.navSubpage === 'kyc' ? 'btn-sm active' : 'btn-sm'} btnType='text'>KYC</ButtonUpperTabs>
								
									<ButtonUpperTabs onClick={() => confirm('/business-profile')} className={navEl.nav.navSubpage === 'business-profile' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Business Profile</ButtonUpperTabs>
							
									<ButtonUpperTabs onClick={() => confirm('/risk-profile')} className={navEl.nav.navSubpage === 'risk-profile' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Risk profile</ButtonUpperTabs>

									<ButtonUpperTabs onClick={() => confirm('/relation')}  className={navEl.nav.navSubpage === 'relation' ? 'btn-sm active' : 'btn-sm'} btnType='text'>Business Relation</ButtonUpperTabs>

								</Col>
								<Col>
									
									<p style={{marginTop: 7}}>
										<b className='client'>{envs.isBusiness ? kycApplication.values.name ? kycApplication.values.name : 'not set' : <>{kycApplication.values?.firstName ? kycApplication.values?.firstName : 'not'} {kycApplication.values?.lastName ? kycApplication.values?.lastName : 'set'}</>}</b>
										{relationStatus ? <> | <Text type="success"><b> Verified</b></Text></> : <>{client?.status && client?.status !== 'PENDING_KYC' && <> | <Text><b>{capitalize(client.status)}</b></Text></>}</>}
										{(!relationStatus || statusInfo === 'BEING_CHANGED' || statusInfo === 'CLARIFICATION_NEEDED' || statusInfo === 'SUBMITTED') && <> | <Text type={statusInfo === 'SUBMITTED' || statusInfo === 'ACCEPTED' ? "success" : statusInfo === 'NEW' ? "secondary" : "secondary"}><b> KYC {statusInfo === 'CLARIFICATION_NEEDED' ? 'Clarification Needed' : statusInfo === 'BEING_CHANGED' ? 'Being Changed' : capitalize(statusInfo)} </b></Text></>}
										<Tooltip title="Copy client's code"><Dropdown.Button style={{display: 'inline-flex', width: 'fit-content', marginLeft: 10, marginTop: '-5px'}} overlayStyle={{width: 102}} onClick={onCodeClick} menu={{ items: client?.codes?.other?.length ? client.codes.other.map((code, ind) => ({
											key: ind,
											label: code,
											style: {cursor: 'copy'}
										})) : [], onClick: onCodeMenuClick }}
										buttonsRender={([leftButton, rightButton]) => [
											//@ts-ignore
											React.cloneElement(leftButton, {style: {width: 70, cursor: 'copy'}, disabled: client?.codes?.active ? false : true}),
											//@ts-ignore
											React.cloneElement(rightButton, {disabled: client?.codes?.other?.length ? false : true}),
										]}
										>{client?.codes?.active ? client.codes.active : 'No code'}</Dropdown.Button></Tooltip>
									</p>
								</Col>
							</Row>
						</div>
					</NavCustom>
				</>
			}
			{!envs.admin && envs.type === 'standalone' && nav.step !== -1 &&
				<KycMessages />
			}
			<FormSaver />
			<div style={{'height': '100%'}}>
				{envs.admin && adminProfile.profile.id === '' && adminProfileLoaded.isFullfilled || adminProfileLoaded.isError ? (
					<Error403 />
				) : (
					<PageContent>		
						{listingView === true ? (
							<Row gutter={30}>
								{children}
							</Row>) : ( 	
							<>	    
							<Row gutter={30}>
							    <Col sm={24} md={hideSteps ? 24 : 12} lg={hideSteps ? 24 : 18} xl={hideSteps ? 24 : 18} id='pageWrapper' style={{marginBottom: envs.admin ? '150px' : '70px'}}>
							    	<Space direction='vertical' size={0} style={{'marginBottom': '15px'}}>
							    		<Space direction='vertical' size={'middle'}>
								    	{pageName && <PageName>{pageName}</PageName>}
								      	{heading ? <Title level={1}>{heading}</Title> : <></>}
								      </Space>
								    	{children}
									</Space>
									{!hideNav &&
										<KycNav approve={approve} validationAction={validationAction} validationActionParams={validationActionParams} hideValidate={hideValidate} hideSaveButton={hideSaveButton} hideApproveStepButton={hideApproveStepButton} setErrorsListing={setErrorsListing} />
									}
									
							     </Col>
							     {!hideSteps &&
									<Col sm={24} md={12} lg={6} xl={6} style={{marginBottom: envs.admin ? '110px' : '30px'}}>
										<Sticky style={{'top': envs.admin && envs.type === 'standalone' ? '130px' : '40px'}}>
											<div>
												<Space direction='vertical' size={30}>
													<Space direction='vertical' size={'middle'}>
														<Title level={5}>KYC steps</Title>
													<KycSteps adminView={adminView} />
													</Space>
												
											</Space>
										</div>
										</Sticky>
									</Col>
								 }
								</Row>
								{envs.admin &&
									<Fixed style={{bottom: 0}}>
										<FooterCustomModule style={{'bottom':'0', 'borderTop': `2px solid ${themeColor.grayWhite}`}}>
											<Row style={{zIndex: 1, background: '#fff', padding: '10px 30px', marginLeft: '-30px', marginRight: '-30px', justifyContent: 'space-between'}}>
								            	<Col span={8}>
										            <FooterDrawer type='message' />
										        </Col>
								            	<Col span={8}>
										            <FooterDrawer type='note' />
										        </Col>
								            </Row>
							            </FooterCustomModule>
						            </Fixed>
								}
								</>
						)}
					</PageContent>
				)}
			</div>
		</>
		
	)
}

export default KycLayout
