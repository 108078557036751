import { ConfigProvider } from 'antd'
import { Locale } from 'antd/es/locale'
import en from 'antd/lib/locale/en_GB'
import de from 'antd/lib/locale/de_DE'
import moment from 'moment/min/moment-with-locales'
import { useTranslation } from 'react-i18next'
import { antdTheme } from 'styles/antdTheme'
import { GlobalStyles } from 'styles/GlobalStyles'
import { SupportedLang } from 'utilities/i18n/i18n'
import 'utilities/i18n/i18n'
import Application from './App';
import { App } from 'antd'
import './styles/font/poppins.css'
import { FC, StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store/store'
import { persistor as backPersistor, store as backStore } from './store/store'
import { ThemeProvider } from 'styled-components'
import 'utilities/overrideObjectToString'
import 'antd/dist/reset.css';
import axios from "axios";
import {initApis} from "./api/kyc/api";
import {initApisBackend} from "./api/kyc-backend/api";

const antdLocale: Record<SupportedLang, Locale> = {
	en,
	// de,
}
export let ENV = {
	ENV: 'local',
	API_URL: '',
	KEYCLOAK_DOMAIN: '',
	DOMAIN: '',
	KEYCLOAK_CLIENT_FRONT: '',
	KEYCLOAK_CLIENT_BACK:'',
	KEYCLOAK_REALM_FRONT:'',
	KEYCLOAK_REALM_BACK:''
}

// Get environment variables
const getEnv = () => {
	return axios.get('/config.json').then(({data}) => {
		ENV = {
			...data
		}

		return Promise.resolve()
	})
}

const RootApp:FC = ()=>{
	const { resolvedLanguage } = useTranslation().i18n
	moment.locale(resolvedLanguage)
	
	return <StrictMode>
		<Provider store={process.env.REACT_APP_KYC === 'admin' ? backStore : store}>
			<PersistGate
				persistor={process.env.REACT_APP_KYC === 'admin' ? backPersistor : persistor}
				loading={<></>}>
				<ThemeProvider theme={{}}>
					<ConfigProvider locale={antdLocale[resolvedLanguage as SupportedLang || SupportedLang.en]} theme={antdTheme}>
						<GlobalStyles/>
						<App>
							<Application/>
						</App>
					</ConfigProvider>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	</StrictMode>
}

const initUi = () => {
	getEnv()
	.then(() => Promise.all([initApis(), initApisBackend()]))
	.then(() => {
		createRoot(document.getElementById('root')!).render(<RootApp/>)
	})
}

window.addEventListener('DOMContentLoaded', () => {
	initUi(); // no need to return in the event
});

