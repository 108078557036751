import { Text, View } from '@react-pdf/renderer'
import { FC, ReactNode } from 'react'
import { Components } from '../../../../../api/kyc/generated/client2'
import { FieldsSection, SignatureSection, TitleSection } from '../../../../../pdf/PdfDocument'
import translateCountries from 'utilities/translateCountries'
import { themeColor } from '../../../../../styles/themeStyles'
import { translatePurposeOfBusinessRelationship } from '../../../../../utilities/translatePurposeOfBusinessRelationship'
import translateSoFName from '../../../../../utilities/translateSoFName'
import { dateFormatDetailed, moment } from '../../../../kyc-front/ui-elements/FormDate'
import {
	isV1ProfileSnapshot,
	isV2CompanyProfileSnapshot,
	isV2IndividualProfileSnapshot, Profile,
} from '../profile.guard'
import { AdminResponse } from 'store/kyc-backend/slices/adminSlice'

type AuthorizedPerson = Components.Schemas.AuthorizedPerson
type ProfileSnapshotV1 = Components.Schemas.ProfileSnapshotV1
type CompanyProfileSnapshot = Components.Schemas.CompanyProfileSnapshot
type IndividualProfileSnapshot = Components.Schemas.IndividualProfileSnapshot
type ProfileSnapshot = Components.Schemas.ProfileSnapshot

const getNationalityLabel = (value?: string): string => value?.includes(',') ? 'Nationalities':'Nationality'

const PersonDetailsSections:FC<{person: AuthorizedPerson, level?: number, preChildren?: ReactNode}> = ({person, level, preChildren}) => <View wrap={false}>
		{preChildren}
		<TitleSection level={level}>Personal details</TitleSection>
		<FieldsSection fields={[
			{ label: 'Name', value: person.name },
			{ label: 'Date of birth', value: person.dob },
			{ label: getNationalityLabel(person.nationality), value: translateCountries(person.nationality) },
			{ label: 'Residence country', value: translateCountries(person.country) }
		]} columns={2}/>
	</View>
	
const BitclearCommentSection:FC<{ comment?: string }> = ({comment})  =>  comment ? <View style={{ display: 'flex', flexDirection: 'row' }}>
	<Text style={{ fontWeight: 'bold', color: themeColor.blueDark }}>Bitclear comment:&nbsp;&nbsp;</Text>
	<Text>{comment}</Text>
</View> : null

type IPdfContentProps<T extends ProfileSnapshot = ProfileSnapshot> = {
	profile: Profile<T>
	adminProfiles: AdminResponse[]
	isCompanyType: boolean
}
export const BpPdfContent:FC<IPdfContentProps> = ({ profile, adminProfiles, isCompanyType }) => {
	const isV1Profile = isV1ProfileSnapshot(profile)
	const isV2Company = isV2CompanyProfileSnapshot(profile)
	const isV2Individual = isV2IndividualProfileSnapshot(profile)
	
	const isApproved = profile.clientAdvisorApproval?.approvedAt && profile.complianceOfficerApproval?.approvedAt
	
	return <>
		<View wrap={false}>
			<TitleSection>Business profile summary</TitleSection>
			<FieldsSection fields={[
				{ label: 'Created at', value: moment(profile.createdAt).format(dateFormatDetailed) },
				{ label: 'Approved at', value: isApproved ? moment(profile.clientAdvisorApproval?.approvedAt).isAfter(profile.complianceOfficerApproval?.approvedAt) ? moment(profile.clientAdvisorApproval?.approvedAt).format(dateFormatDetailed) : moment(profile.complianceOfficerApproval?.approvedAt).format(dateFormatDetailed) : ''},
				{ label: 'Version', value: profile.formVersion },
			]} columns={3} />
		</View>
		{isV1Profile ? <V1PdfContent profile={profile} adminProfiles={adminProfiles} isCompanyType={isCompanyType} /> : null}
		{isV2Company || isV2Individual ? <V2PdfContent profile={profile} adminProfiles={adminProfiles} /> : null}
	</>
}

const V2PdfContent:FC<Pick<IPdfContentProps<CompanyProfileSnapshot|IndividualProfileSnapshot>, 'profile'|'adminProfiles'>> = ({ profile, adminProfiles }) => {
	const getAdminName = (adminId?: string) => adminProfiles.find((el) => el.id === adminId)?.name
	const data = profile.data
	const isCompany = isV2CompanyProfileSnapshot(profile)
	const isIndividual = isV2IndividualProfileSnapshot(profile)
	if(!data){
		return null
	}
	
	return <>
		<View wrap={false}>
			<TitleSection>Basic {isCompany ? 'Company ':''} details</TitleSection>
			<FieldsSection fields={[
				{ label: isCompany ? 'Name of legal entity' : 'Client name', value: data.basicInformation?.name },
				{ label: 'Legal form', value: isCompany && profile.data?.basicInformation?.legalForm, hide: !isCompany },
			]}/>
			<BitclearCommentSection comment={data.basicInformationComments} />
		</View>
		<View wrap={false}>
			<TitleSection>{isCompany ? 'Registered address' : 'Address'}</TitleSection>m
			<FieldsSection fields={[
				{ label: 'Address', value: data.basicInformation?.address },
				{ label: 'Postcode', value: data.basicInformation?.postcode },
				{ label: 'City', value: data.basicInformation?.city },
				{ label: 'Country', value: translateCountries(data.basicInformation?.country) },
				{ label: 'Date of incorporation', value: isCompany && profile.data?.basicInformation?.dateOfIncorporation, hide: !isCompany },
				{ label: 'Registration number', value: isCompany && profile.data?.basicInformation?.registrationNumber, hide: !isCompany },
				{ label: 'Place of incorporation', value: isCompany && profile.data?.basicInformation?.placeOfIncorporation, hide: !isCompany },
			]} columns={2}/>
			<BitclearCommentSection comment={data.addressComments} />
		</View>
		<View wrap={false}>
			<TitleSection>Source of Funds (SoF) / Source of Wealth (Sow)</TitleSection>
			<FieldsSection fields={[
				{ label: 'Funding of business activities (SoF)', value: isCompany && translateSoFName(profile.data?.sourceOfFunds?.fundingOfBusinessActivities), hide: !isCompany },
				{ label: 'Further explanation (mandatory if not by UBO)', value: data.sourceOfFunds?.furtherExplanation },
				{ label: 'Business background and job of the effective contributor(s) (SoW)', value: isIndividual && profile.data?.sourceOfFunds?.businessBackgroundOfContributor, hide: !isIndividual },
			]} columns={2}/>
			<BitclearCommentSection comment={data.sourceOfWealthComments} />
		</View>
		<View wrap={false}>
			<TitleSection>Purpose of Entity / Use of Funds</TitleSection>
			<FieldsSection fields={[
				{ label: 'Website', value: isCompany && profile.data?.purposeOfEntity?.website, hide: !isCompany },
				{ label: 'Nature of business activities and business model', value: isCompany && profile.data?.purposeOfEntity?.businessDescription, hide: !isCompany },
				{ label: 'Countries and regions of operation', value: isCompany && translateCountries(profile.data?.purposeOfEntity?.countriesOfOperation), hide: !isCompany },
				{ label: 'Approximate annual turnover (EUR)', value: data.purposeOfEntity?.approximateAnnualTurnoverEur },
				{ label: 'Estimated amount of future Crypto transactions with Bitclear p.a. (in EUR)', value: isCompany && profile.data?.purposeOfEntity?.estimatedOfCryptoTransactionsEur, hide: !isCompany },
			]} columns={2}/>
			<BitclearCommentSection comment={data.purposeOfEntityComments} />
		</View>
		{isCompany ? <>
			{profile.data?.authorizedPersonsUBOs?.directors?.map((person, i) =>
				<PersonDetailsSections
					person={person}
					level={2}
					key={i}
					preChildren={<>
						{i===0 ? <TitleSection>Authorized Persons and UBOs</TitleSection> : null}
						<TitleSection level={2}>{++i}. {person.name}</TitleSection>
					</>}
				/>
			)}
			{profile.data?.authorizedPersonsUBOs?.otherAuthorizedPersons?.map((person, i) =>
				<PersonDetailsSections
					person={person}
					level={2}
					key={i}
					preChildren={<>
						{i===0 ? <TitleSection>Other Authorized Persons</TitleSection> : null}
						<TitleSection level={2}>{++i}. {person.name}</TitleSection>
					</>}
				/>
			)}
			{profile.data?.authorizedPersonsUBOs?.beneficialOwners?.map((person, i) =>
				<PersonDetailsSections
					person={person}
					level={2}
					key={i}
					preChildren={<>
						{i===0 ? <TitleSection>Ultimate Beneficial Owners</TitleSection> : null}
						<TitleSection level={2}>{++i}. {person.name}</TitleSection>
					</>}
				/>
			)}
			<BitclearCommentSection comment={profile.data?.authorizedPersonAndUBOComments} />
		</> : null}
		<View wrap={false}>
			<TitleSection>Business Profile Approvals</TitleSection>
			<SignatureSection signatures={[
				{ labels: [`Client Advisor`, `${moment(profile.clientAdvisorApproval?.approvedAt).format(dateFormatDetailed)}`], value: getAdminName(profile.clientAdvisorApproval?.approvedBy) },
				{ labels: [`Compliance Officer`, `${moment(profile.complianceOfficerApproval?.approvedAt).format(dateFormatDetailed)}`], value: getAdminName(profile.complianceOfficerApproval?.approvedBy) },
			]}/>
		</View>
	</>
}

const V1PdfContent:FC<IPdfContentProps<ProfileSnapshotV1>> = ({ profile, adminProfiles, isCompanyType }) => {
	const getAdminName = (adminId?: string) => adminProfiles.find((el) => el.id === adminId)?.name
	const data = profile.data
	if(!data){
		return null
	}
	
	return <>
		<View wrap={false}>
			<TitleSection>Basic {isCompanyType ? 'Company ':''} details</TitleSection>
			<FieldsSection fields={[
				{ label: isCompanyType ? 'Name of legal entity' : 'Client name', value: data.basicInformation?.name },
				{ label: 'Legal form', value: data.basicInformation?.legalForm, hide: !isCompanyType },
			]}/>
		</View>
		<View wrap={false}>
			<TitleSection>{isCompanyType ? 'Registered address' : 'Address'}</TitleSection>
			<FieldsSection fields={[
				{ label: 'Address', value: data.basicInformation?.address },
				{ label: 'Postcode', value: data.basicInformation?.postcode },
				{ label: 'City', value: data.basicInformation?.city },
				{ label: 'Date of incorporation', value: data.basicInformation?.dateOfIncorporation, hide: !isCompanyType },
				{ label: 'Registration number', value: data.basicInformation?.registrationNumber, hide: !isCompanyType },
				{ label: 'Place of incorporation', value: data.basicInformation?.placeOfIncorporation, hide: !isCompanyType },
			]} columns={2}/>
		</View>
		<View wrap={false}>
			<TitleSection>Source of Funds (SoF) / Source of Wealth (Sow)</TitleSection>
			<FieldsSection fields={[
				{ label: 'Funding of business activities (SoF)', value: translateSoFName(data.sourceOfFunds?.fundingOfBusinessActivities) },
				{ label: 'Further explanation (mandatory if not by UBO)', value: data.sourceOfFunds?.furtherExplanation },
				{ label: 'Effective Contributor(s) of funds', value: data.sourceOfFunds?.effectiveContributorOfFunds },
				{ label: 'Business background and job of the effective contributor(s) (SoW)', value: data.sourceOfFunds?.businessBackgroundOfContributor },
			]} columns={2}/>
		</View>
		<View wrap={false}>
			<TitleSection>Purpose of Entity / Use of Funds</TitleSection>
			<FieldsSection fields={[
				{ label: 'Website', value: data.purposeOfEntity?.website || '-', hide: !isCompanyType },
				{ label: 'Nature of business activities and business model', value: data.purposeOfEntity?.businessDescription, hide: !isCompanyType },
				{ label: 'Countries and regions of operation', value: translateCountries(data.purposeOfEntity?.countriesOfOperation), hide: !isCompanyType },
				{ label: 'Approximate annual turnover (EUR)', value: data.purposeOfEntity?.approximateAnnualTurnoverEur },
				{ label: 'Estimated amount of future Crypto transactions with Bitclear p.a. (in EUR)', value: data.purposeOfEntity?.estimatedOfCryptoTransactionsEur },
				{ label: 'Purpose of business relationship', value: translatePurposeOfBusinessRelationship(data.purposeOfEntity?.purposeOfBusinessRelationship) },
				{ label: 'Name(s) of main bank(s)', value: data.purposeOfEntity?.banks },
			]} columns={2}/>
		</View>
		{isCompanyType ? <>
			{data.authorizedPersonsUBOs?.directors?.map((person, i) =>
				<PersonDetailsSections
					person={person}
					level={2}
					key={i}
		       preChildren={<>
		         {i===0 ? <TitleSection>Authorized Persons and UBOs</TitleSection> : null}
		         <TitleSection level={2}>{++i}. {person.name}</TitleSection>
		       </>}
				/>
			)}
			{data.authorizedPersonsUBOs?.otherAuthorizedPersons?.map((person, i) =>
				<PersonDetailsSections
					person={person}
					level={2}
					key={i}
		       preChildren={<>
		         {i===0 ? <TitleSection>Other Authorized Persons</TitleSection> : null}
		         <TitleSection level={2}>{++i}. {person.name}</TitleSection>
		       </>}
				/>
			)}
			{data.authorizedPersonsUBOs?.beneficialOwners?.map((person, i) =>
				<PersonDetailsSections
					person={person}
					level={2}
					key={i}
		       preChildren={<>
		         {i===0 ? <TitleSection>Ultimate Beneficial Owners</TitleSection> : null}
		         <TitleSection level={2}>{++i}. {person.name}</TitleSection>
		       </>}
				/>
			)}
		</> : null}
		<View wrap={false}>
			<TitleSection>Business Profile Approvals</TitleSection>
			<SignatureSection signatures={[
				{ labels: [`Client Advisor`, `${moment(profile.clientAdvisorApproval?.approvedAt).format(dateFormatDetailed)}`], value: getAdminName(profile.clientAdvisorApproval?.approvedBy) },
				{ labels: [`Compliance Officer`, `${moment(profile.complianceOfficerApproval?.approvedAt).format(dateFormatDetailed)}`], value: getAdminName(profile.complianceOfficerApproval?.approvedBy) },
			]}/>
		</View>
	</>
}