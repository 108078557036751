import { createSlice } from "@reduxjs/toolkit"
import { CaseReducerType } from "../../store"
import { signedDocsGet } from "../asyncThunks/signedDocsGet"
import { signedDocCreate } from "../asyncThunks/signedDocCreate"
import { Components, Paths } from "api/kyc/generated/client2"
import { signedDocDelete } from "../asyncThunks/signedDocDelete"
import { signedDocApprove } from "../asyncThunks/signedDocApprove"

export type DeletedResponse = Paths.DeleteSignedKyc.Responses.$200
export type ApprovedDocResponse = Paths.ApproveSignedKyc.Responses.$200

type DocInfo = Components.Schemas.WithApprovalInfo

type DocResponse = {
    id: string;
    fileName: string;
    mimeType: string;
}

type State = {
    docsList: DocResponse[];
    newSignedDoc: DocInfo;
    docDeleted: {id: string, value: DeletedResponse};
    signedDocApproved: {name: string, data: ApprovedDocResponse};
};

const initialState: State = {
    docsList: [],
    newSignedDoc: {
        documentId: '',
        fileName: '',
        mimeType: ''
    },
    docDeleted: {id: '', value: false},
    signedDocApproved: {name: '', data: false}
};

type CaseReducers<State> = {
    signedDocsGetClear: CaseReducerType<State, object>;
    signedDocCreateClear: CaseReducerType<State, object>;
    signedDocDeleteClear: CaseReducerType<State, object>;
    signedDocApproveClear: CaseReducerType<State, object>;
};

export const signedDocsSlice = createSlice<State, CaseReducers<State>, string, {}>({
    name: "signedDocs",
    initialState,
    reducers: {
        signedDocsGetClear: (state, { payload }) => {
            state.docsList = initialState.docsList
        },
        signedDocCreateClear: (state, { payload }) => {
            state.newSignedDoc = initialState.newSignedDoc
        },
        signedDocDeleteClear: (state, { payload }) => {
            state.docDeleted = initialState.docDeleted
        },
        signedDocApproveClear: (state, { payload }) => {
            state.signedDocApproved = initialState.signedDocApproved
        }
      },
      extraReducers: (builder) => {
        builder.addCase(signedDocsGet.fulfilled, (state, action) => {
            state.docsList = action.payload
        });
        builder.addCase(signedDocCreate.fulfilled, (state, action) => {
            state.newSignedDoc = action.payload;
        });
        builder.addCase(signedDocCreate.rejected, (state, action) => {
            //@ts-ignore
            state.newSignedDoc = action.payload;
        });
        builder.addCase(signedDocDelete.fulfilled, (state, action) => {
            state.docDeleted = action.payload;
        });
        builder.addCase(signedDocApprove.fulfilled, (state, action) => {
            state.signedDocApproved = {name: action.payload.name, data: action.payload.data};
        });
    }
});

export const { signedDocsGetClear, signedDocCreateClear, signedDocDeleteClear, signedDocApproveClear } = signedDocsSlice.actions;
export default signedDocsSlice.reducer;
