import { createAsyncThunk } from "@reduxjs/toolkit"
import { api2 } from "api/kyc/api"
import { AsyncThunkActionType } from "../../store"
import { AsyncThunkDataType } from "../../common"
import { AdminResponse } from "../slices/adminSlice"
import { Paths } from "api/kyc/generated/client2"

type PathParameters = Paths.GetProfiles.QueryParameters

export const adminProfilesGet: AsyncThunkActionType<
  AdminResponse[],
  AsyncThunkDataType<PathParameters, null, "params">
> = createAsyncThunk("adminProfilesGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api2.getProfiles(data?.params, null, data?.config);
    return response.data as AdminResponse[];
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
