import TextArea from 'antd/es/input/TextArea'
import { Field, FieldProps } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { Row, Col, Input } from 'antd'
import { themeColor } from '../../../styles/themeStyles'
import styled from 'styled-components'
import { useSelector } from '../../../hooks/useSelector'
import ApproveInput from "../../kyc-backend/ui-elements/Approve"
import { NumericFormat } from "react-number-format"
import EditedInputInfo from './EditedInput'

const PrintWrapper = styled.div`
    border: 1px solid #c4c4c4;
    padding: 4px 11px;
    display: none;
    height: 32px;
    line-height: 20px;
    border-radius: 2px;
    &.textarea {
        height: auto;
        min-height: 315px;
        line-height: 18px;
    }
`

const CustomRow = styled(Row)`
    margin-bottom: 0;
    .ant-form-item-label {
        position: relative;
    }
`

const CustomInput = styled(Field)`
    height: 40px;
    &.ant-input.input-textarea {
        height: 140px;
        resize: none;
    }
    &.error {
        box-shadow: 0 0 2px 1px ${themeColor.red};
    }
    &:disabled {
        background-color: ${themeColor.grayWhite};
        color: ${themeColor.gray}
    }
    &::placeholder {
        color: ${themeColor.gray}
    }
    &.edited {
        background-color: ${themeColor.orangeLight};
        &:hover, &:focus, &:active, &:focus-within {
            border-color: ${themeColor.orangeWarning};
        }
        &:focus, &:focus-within {
            box-shadow: 0 0 0 2px ${themeColor.orangeLight};
        }
    }
`

const CustomAntInput = styled(Input)`
    height: 40px;
    &.ant-input.input-textarea {
        height: 140px;
        resize: none;
    }
    &.error {
        box-shadow: 0 0 2px 1px ${themeColor.red};
    }
    &:disabled {
        background-color: ${themeColor.grayWhite};
        color: ${themeColor.gray}
    }
    &::placeholder {
        color: ${themeColor.gray}
    }
`

const SubLabel = styled.label`
    color: ${themeColor.gray};
`

const ErrorContainer = styled.div`
    margin-top: 3px;
    color: ${themeColor.red};
`

interface NProps {
        field: any,
        className: string,
        autoComplete: boolean,
        onInput: any,
        disabled: boolean
}


const NumberField: FC<NProps> = ({field, className, autoComplete, onInput, disabled}) => {
    return <NumericFormat 
            {...field}
            name={field.name}
            className={className}
            autoComplete={autoComplete}
            onInput={onInput}
            disabled={disabled}
            isNumericString={true}
            thousandSeparator={true} 
            allowNegative={false}
            customInput={Input}
    />;
}

interface IProps {
    label: string,
    name:  string,
    error?: string,
    type: 'authorized-persons' | 'ubos' | 'individual' | 'company',
    inputType?: 'text' | 'password',
    component?: 'textarea'|'input',
    subLabel?: string,
    subLabelClass?: string,
    disabled?: boolean,
    value: string,
    onChange?: (value: any) => void,
    printRender?: boolean,
    showApprove?: boolean,
    schemaParent?: string,
    schemaName?: string,
    personId?: string,
    formatting?: 'numbers', 
    placeholder?: string,
    id?: string,
    className?: any,
    edited?: boolean
}

interface SProps {
    label: string,
    name: string,
    onChange: (value: any) => void,
    onFocusOut?:  (value: any) => void,
    inputClass?: string
    inputType?: 'text' | 'password',
    disabled?: boolean,
    placeholder?: string,
    value: string
}

const InputPasswordComponent:React.ComponentType<FieldProps<any>> = ({field, meta, form,...props}) => <Input.Password {...field} {...props}/>
const InputComponent:React.ComponentType<FieldProps<any>> = ({field, meta, form,...props}) => <Input {...field} {...props}/>
const TextAreaComponent:React.ComponentType<FieldProps<any>> = ({field, meta, form,...props}) => <TextArea {...field} {...props}/>

const FormInput: FC<IProps> = ({
    label, name, error, component= 'input', subLabel, subLabelClass, disabled, value, onChange, printRender, schemaParent, schemaName, type, inputType, personId, formatting, showApprove, placeholder, id, className, edited
}) => {
    const FieldComponent = component === 'textarea' ? TextAreaComponent : inputType === 'password' ? InputPasswordComponent : InputComponent
    const [isError, setIsError] = useState(false);
    const envs = useSelector((state) => state.general2.envs);
    let inputClass = 'ant-input ';
    const onInputChange = (el: any) => {
        //@ts-ignore
        onChange(el);
    }
    const onBlur = (el: any) => {
        if(el.target.value !== '') {
            setIsError(false)
        } else {
            if(isError === false && error?.length) {
                setIsError(true)
            }
        }
    }
    useEffect(() => {
        if(error?.length) {
            if(isError !== true) {
                setIsError(true)
                inputClass += 'error ';
            }
        } else {
            if(isError !== false) {
                setIsError(false)
            }
        } 
        
    }, [error])

    if(isError) {
        inputClass += ' error '
    }
    if(component === 'textarea') {
        inputClass += 'input-textarea';
    }
    if(edited) {
        inputClass += ' edited'
    }
    return (
        <CustomRow className={className ? className + ' ant-form-item' : 'ant-form-item'}>
            <label className='ant-form-item-label' htmlFor={name}>
                <Row justify="space-between">
                    <Col>{label}</Col>
                    <Row>
                        {envs.admin && showApprove !== false &&
                            <ApproveInput name={name} schemaParent={schemaParent} schemaName={schemaName} personId={personId} type={type} />
                        }
                        <EditedInputInfo name={name} type={type} />
                    </Row>
                </Row>
            </label>
            {subLabel &&
                <SubLabel className={subLabelClass ? subLabelClass + ' ant-form-item-label' : 'ant-form-item-label'}>{subLabel}</SubLabel>
            }
            
            <div className='ant-form-item-control'>
                {formatting === 'numbers' ? (
                    <CustomInput cols='60' rows='8' onInput={onInputChange} onBlur={onBlur} className={inputClass} name={name} type={inputType ? inputType : "text"} autoComplete="off" component={NumberField} disabled={disabled} placeholder={placeholder} />
                ) : (
            	    <CustomInput cols='60' rows='8' onInput={onInputChange} onBlur={onBlur} className={inputClass} name={name} type={inputType ? inputType : "text"} autoComplete="off" component={FieldComponent} disabled={disabled} placeholder={placeholder} />
                )}
                {printRender &&
                    <PrintWrapper className={component === 'textarea' ? 'print-wrapper textarea' : 'print-wrapper'} id={id}>
                        <p>{value}</p>
                    </PrintWrapper>
                }
            </div>
            <ErrorContainer>
                {/*<ErrorMessage name={name} />*/}
                {isError ? (
                    <div>{error}</div>
                ) : null}
            </ErrorContainer>
        </CustomRow>
			 
	)
}

export const FormInputStandalone: FC<SProps> = ({label, name, onChange, onFocusOut, inputClass, inputType, disabled, placeholder, value}) => (
     <CustomRow className='ant-form-item'>
            <label className='ant-form-item-label' htmlFor={name}>
                <Row justify="space-between">
                    <Col>{label}</Col>
                </Row>
            </label>
            <div className='ant-form-item-control'>
                <CustomAntInput onInput={onChange} onBlur={onFocusOut} className={inputClass} name={name} placeholder={placeholder} value={value} type={inputType ? inputType : "text"} autoComplete="off"  />
            </div>
        </CustomRow>
)

export default FormInput
