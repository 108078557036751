import { FC, ReactNode, useEffect } from 'react'
import { setFormUpdated } from 'store/kyc-front/slices/navSlice'
import { dispatch } from 'store/store'
import { kycApplicationApprovalsGetClear } from 'store/kyc-backend/slices/backKycApplication'
import { kycApplicationGetClear, kycApplicationValidateClear } from 'store/kyc-front/slices/kycApplicationSlice'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import { useSelector } from 'hooks/useSelector'
import { kycApplicationChangesGetClear } from 'store/kyc-front/slices/kycApplicationChangesSlice'
import { signedDocsGetClear } from 'store/kyc-backend/slices/signedDocsSlice'
import { clientGetClear } from 'store/kyc-backend/slices/clientsSlice'
import { riskProfilesGetClear } from 'store/kyc-backend/slices/riskSlice'
import { businessProfilesGetClear } from 'store/kyc-backend/slices/businessProfileSlice'

const ClearUserState: FC<{ children?: ReactNode }> = ({ children }) => {
    const envs = useSelector((state) => state.general2.envs)
    const {apiURL, admin, adminSuffix, type, env, routeSuffix} = envs
    useEffect(() => {
        dispatch(kycApplicationGetClear({}))
        dispatch(kycApplicationValidateClear({}))
        dispatch(kycApplicationChangesGetClear({}))
        dispatch(kycApplicationApprovalsGetClear({}))
        dispatch(setFormUpdated({updated: false, form: []}))
        dispatch(signedDocsGetClear({}))
        dispatch(riskProfilesGetClear({}))
        dispatch(businessProfilesGetClear({}))
        dispatch(clientGetClear({}))
        dispatch(setEnvs({apiURL, admin, adminSuffix, type, env, routeSuffix, profileId: '', clientId: '', accountType: ''}))
	}, []);
    return <>{children}</>
	
}
export const AdminRoute: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<ClearUserState>
			{children}
		</ClearUserState>
	)
}
