import { languages } from 'countries-list'
import i18next, { PostProcessorModule } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import TranslationEn from './locales/translation_en.json'
import TranslationDe from './locales/translation_de.json'

export enum SupportedLang {
	en = 'en',
	// de = 'de',
}
export const lang: Record<SupportedLang, { native: string; translation: typeof TranslationEn }> = {
	en: {
		native: languages['en'].native,
		translation: TranslationEn,
	},
	// de: {
	// 	native: languages['de'].native,
	// 	translation: TranslationDe,
	// },
}

// change translated text into ---
const translationDebugger: PostProcessorModule = {
	name: 'translationDebugger',
	type: 'postProcessor',
	process: (v: string) => {
		// return v.replace(/./g, '-')
		return v
	},
}

i18next
	// detect user language
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	.use(translationDebugger)
	// init i18next https://www.i18next.com/overview/configuration-options
	.init({
		supportedLngs: Object.keys(SupportedLang),
		fallbackLng: SupportedLang.en,
		debug: true,
		resources: Object.keys(lang).reduce((acc, v) => 
			({ ...acc, [v]: { translation:lang[v as SupportedLang].translation } }), 
		{}),
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		postProcess: ['translationDebugger'],
	})

export default i18next

/**
 * use this method to be sure translation key exist
 *
 * why: Intellisense is not working in react-i18next, and this solution makes it for us
 */
type Leaves<T> = T extends object
	? { [K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never ? '' : `.${Leaves<T[K]>}`}` }[keyof T]
	: never
export const langKey = (key: Leaves<typeof TranslationEn>) => key
