import { createSlice } from "@reduxjs/toolkit"
import { Paths } from "api/kyc/generated/client2"
import { kycApplicationGet, ProfileResponse } from "store/kyc-front/asyncThunks/kycApplicationGet"
import { kycApplicationUpdate } from "store/kyc-front/asyncThunks/kycApplicationUpdate"
import { kycApplicationSectionCreate } from "store/kyc-front/asyncThunks/kycApplicationSectionCreate"
import { kycApplicationSectionUpdate } from "store/kyc-front/asyncThunks/kycApplicationSectionUpdate"
import { kycApplicationValidate } from "store/kyc-front/asyncThunks/kycApplicationValidate"
import { kycApplicationSubSectionDelete } from "store/kyc-front/asyncThunks/kycApplicationSubSectionDelete"
import { APSteps, UboSteps, Step } from 'stepsScheme'
import { CaseReducerType } from "../../store"
import { kycApplicationDocCreate } from "store/kyc-front/asyncThunks/kycApplicationDocCreate"
import { kycApplicationSectionDocCreate } from "store/kyc-front/asyncThunks/kycApplicationSectionDocCreate"
import { kycApplicationSectionDelete } from "store/kyc-front/asyncThunks/kycApplicationSectionDelete"
import { kycApplicationSubmit } from "store/kyc-front/asyncThunks/kycApplicationSubmit"

interface ValidationError {
    message: string;
    ref: string;
    slug: string;
}

// this interface has to be exported, otherwise TS complain about no named module in store
export interface StepErrors {
	step: number;
    substep?: number;
    link: string;
    name: string;
  	validated: boolean;
	validationErrors: ValidationError[];
    refId?: string;
}

type State = {
    kycApplication: ProfileResponse;
    updatedKycApplication: Paths.UpdateApplication.Responses.$200,
    updatedKycApplicationSection: Paths.UpdateSection.Responses.$200,
    newSection: {
        created: boolean,
        id: any
    },
    kycApplicationSubmited: boolean,
    newDoc: {uploaded: boolean, status: string | number}
    newSectionDoc: {uploaded: boolean, status: string | number},
    subSectionDeleted: Paths.DeleteSection1.Responses.$200,
    sectionDeleted: Paths.DeleteSection.Responses.$200,
    validation: StepErrors[],
    validationFullResponse: any,
    stepsScheme: Step[],
};

const initialState: State = {
    kycApplication: {
        id: '',
        values: {}
    },
    updatedKycApplication: false,
    updatedKycApplicationSection: false,
    newSection: {
        created: false,
        id: ''
    },
    kycApplicationSubmited: false,
    newDoc: {uploaded: false, status: ''},
    newSectionDoc: {uploaded: false, status: ''},
    sectionDeleted: false,
    subSectionDeleted: false,
    validation: [],
    validationFullResponse: {},
    stepsScheme: []
};

type CaseReducers<State> = {
    kycApplicationGetClear: CaseReducerType<State, object>;
    kycApplicationUpdateClear: CaseReducerType<State, object>;
    kycApplicationSectionCreateClear: CaseReducerType<State, object>;
    kycApplicationSectionUpdateClear: CaseReducerType<State, object>;
    kycApplicationValidateClear: CaseReducerType<State, object>;
    kycApplicationDocCreateClear: CaseReducerType<State, object>;
    kycApplicationSectionDocCreateClear: CaseReducerType<State, object>;
    kycApplicationSectionDeleteClear: CaseReducerType<State, object>;
    kycApplicationSubSectionDeleteClear: CaseReducerType<State, object>;
    kycApplicationSubmitClear: CaseReducerType<State, object>;
    setStepsScheme: CaseReducerType<State, Step[]>;
};

const parseErrors = (state: State, elem: any) => {
    const steps = state.stepsScheme;
    let payloadEl: StepErrors[] = [];
    steps.forEach((step) => {
        const stepFields = step.type === 'authorizedPersons' ? APSteps.flatMap(step => step.fields) : step.type === 'beneficialOwners' ? UboSteps.flatMap(step => step.fields) : step.fields || [];
        const personsRefList = step.ref === 'ap' ? elem.payload?.authorizedPersons : step.ref === 'ubo' ? elem.payload?.beneficialOwners : elem.payload;
        const validationErrosList = step.refId ? personsRefList?.find((el: any) => el.id === step.refId) : step.type && elem.payload ? elem?.payload[step.type] : elem.payload;
        let errorsByStep: ValidationError[] = validationErrosList?.validationErrors?.filter((error: ValidationError) => {
            if (error.ref !== '') {
                return stepFields.includes(error.ref + '.' + error.slug);
            } else {
                return stepFields.includes(error.slug);
            }
        }) || [];
        let prev = JSON.parse(JSON.stringify(state.validation));
        const previousState = prev.find((s: StepErrors) => s.step === step?.step);
        const metaParams = elem.meta?.arg.params;
        const showErrors = metaParams?.showErrors;
        const isStepMatch = metaParams?.step === step?.step && (metaParams?.substep === undefined || metaParams?.substep === step?.substep) && (metaParams.refId === undefined || metaParams.refId === step?.refId)
        //@ts-ignore
        const shouldBeValidated = showErrors === false ? false : previousState ? previousState.validated === false ? isStepMatch ? true : false : false : false;
        payloadEl.push({
            step: step?.step,
            substep: step?.substep,
            link: step.link,
            name: step.name,
            validated: shouldBeValidated,
            validationErrors: errorsByStep,
            refId: step.refId,
        });
        
    });
    return payloadEl;
}

export const kycApplicationSlice =  createSlice<State, CaseReducers<State>, string, {}>({
    name: "kycApplication",
    initialState,
    reducers: {
        kycApplicationGetClear: (state, { payload }) => {
            state.kycApplication = initialState.kycApplication
        },
        kycApplicationUpdateClear: (state, { payload }) => {
            state.updatedKycApplication = initialState.updatedKycApplication
        },
        kycApplicationSectionCreateClear: (state, { payload }) => {
            state.newSection = initialState.newSection
        },
        kycApplicationSectionUpdateClear: (state, { payload }) => {
            state.updatedKycApplicationSection = initialState.updatedKycApplicationSection
        },
        kycApplicationValidateClear: (state, { payload }) => {
            state.validation = initialState.validation
            state.validationFullResponse = initialState.validationFullResponse
        },
        kycApplicationDocCreateClear: (state, { payload }) => {
            state.newDoc = initialState.newDoc
        },
        kycApplicationSectionDocCreateClear: (state, { payload }) => {
            state.newSectionDoc = initialState.newSectionDoc
        },
        kycApplicationSectionDeleteClear: (state, { payload }) => {
            state.sectionDeleted = initialState.sectionDeleted
        },
        kycApplicationSubSectionDeleteClear: (state, { payload }) => {
            state.subSectionDeleted = initialState.subSectionDeleted
        },
        kycApplicationSubmitClear: (state, { payload }) => {
            state.kycApplicationSubmited = initialState.kycApplicationSubmited
        },
        setStepsScheme: (state, {payload}) => {
            state.validation = parseErrors(state, state.validationFullResponse)
            state.stepsScheme = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(kycApplicationGet.fulfilled, (state, action) => {
            state.kycApplication = action.payload;
        });
        builder.addCase(kycApplicationUpdate.fulfilled, (state, action) => {
            state.updatedKycApplication = true;
        });
        builder.addCase(kycApplicationSectionCreate.fulfilled, (state, action) => {
            state.newSection = {
                created: true,
                id: action.payload
            }
        });
        builder.addCase(kycApplicationSectionUpdate.fulfilled, (state, action) => {
            state.updatedKycApplicationSection = true;
        });
        builder.addCase(kycApplicationValidate.fulfilled, (state, action) => {
            state.validationFullResponse = action
            state.validation = parseErrors(state, action)
        });
        builder.addCase(kycApplicationDocCreate.fulfilled, (state, action) => {
            state.newDoc = {uploaded: true, status: 200}
        });
        builder.addCase(kycApplicationDocCreate.rejected, (state, action) => {
            //@ts-ignore
            state.newDoc = {uploaded: false, status: action?.payload?.status | 400}
        });
        builder.addCase(kycApplicationSectionDocCreate.fulfilled, (state, action) => {
            state.newSectionDoc = {uploaded: true, status: 200}
        });
        builder.addCase(kycApplicationSectionDocCreate.rejected, (state, action) => {
            //@ts-ignore
            state.newSectionDoc = {uploaded: false, status: action?.payload?.status | 400}
        });
        builder.addCase(kycApplicationSectionDelete.fulfilled, (state, action) => {
            state.sectionDeleted = true;
        });
        builder.addCase(kycApplicationSubSectionDelete.fulfilled, (state, action) => {
            state.subSectionDeleted = true;
        });
        builder.addCase(kycApplicationSubmit.fulfilled, (state, action) => {
            state.kycApplicationSubmited = true;
        });
    },
});

export const { kycApplicationGetClear, kycApplicationUpdateClear, kycApplicationSectionCreateClear, kycApplicationSectionUpdateClear, kycApplicationValidateClear, kycApplicationDocCreateClear, kycApplicationSectionDocCreateClear, kycApplicationSectionDeleteClear, kycApplicationSubSectionDeleteClear, kycApplicationSubmitClear, setStepsScheme } = kycApplicationSlice.actions;
export default kycApplicationSlice.reducer;
