import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { api2 } from "api/kyc/api";
import { AdminResponse } from "../slices/adminSlice";


export const adminProfileGet: AsyncThunkActionType<
  AdminResponse,
  AsyncThunkDataType<null, null, "params">
> = createAsyncThunk("adminProfileGet", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api2.getProfile(data.params, null, data?.config);
    return response.data as AdminResponse;
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
