import { createSlice } from "@reduxjs/toolkit"
import { Components, Paths } from "../../../api/kyc/generated/client2"
import { CaseReducerType } from "../../store"
import { businessProfilesGet } from "../asyncThunks/businessProfilesGet"
import { businessProfileUpdate } from "../asyncThunks/businessProfileUpdate";
import { businessProfileCreate } from "../asyncThunks/businessProfileCreate";
import { businessProfileDelete } from "../asyncThunks/businessProfileDelete";
import { businessProfileApprove } from "../asyncThunks/businessProfileApprove";


export type BusinessProfileResponse = Components.Schemas.BusinessProfile;
export type ProfileResponse = BusinessProfileResponse[]
type UpdatedResponse = Paths.SetProfileComments.Responses.$204
type CreatedResponse = Components.Schemas.CreateBusinessProfileResponse
export type DeletedResponse = Paths.DeleteBusinessProfile.Responses.$204
export type ApprovedResponse = Paths.ApproveProfile.Responses.$204


type State = {
    businessProfiles: ProfileResponse,
    newBusinessProfile: CreatedResponse,
    businessProfileUpdated: UpdatedResponse,
    businessProfileDeleted: DeletedResponse,
    businessProfileApproved: ApprovedResponse
}

export const emptyProfile = {
    id: '',
    createdAt: '',
    formVersion: '',
    data: '',
    status: ''
}

export const emptyBusinessProfile = {
    basicInformation: {},
    authorizedPersonsUBOs: {},
    sourceOfFunds: {},
    purposeOfEntity: {}
}

const initialState: State = {
    businessProfiles: [],
    newBusinessProfile: {businessProfileId: ''},
    businessProfileUpdated: false,
    businessProfileDeleted: false,
    businessProfileApproved: false
};

type CaseReducers<State> = {
    businessProfilesGetClear: CaseReducerType<State, object>;
    businessProfileCreateClear: CaseReducerType<State, object>;
    businessProfileUpdateClear: CaseReducerType<State, object>;
    businessProfileDeleteClear: CaseReducerType<State, object>;
    businessProfileApproveClear: CaseReducerType<State, object>;
};

export const businessProfilesSlice = createSlice<State, CaseReducers<State>, string, {}>({
    name: "businessProfiles",
    initialState,
    reducers: {
        businessProfilesGetClear: (state, { payload }) => {
            state.businessProfiles = initialState.businessProfiles
        },
        businessProfileCreateClear: (state, { payload }) => {
            state.businessProfileUpdated = initialState.businessProfileUpdated
        },
        businessProfileUpdateClear: (state, { payload }) => {
            state.newBusinessProfile = initialState.newBusinessProfile
        },
        businessProfileDeleteClear: (state, { payload }) => {
            state.businessProfileDeleted = initialState.businessProfileDeleted
        },
        businessProfileApproveClear: (state, { payload }) => {
            state.businessProfileApproved = initialState.businessProfileApproved
        }
    },
    extraReducers: (builder) => {
        builder.addCase(businessProfilesGet.fulfilled, (state, action) => {
            state.businessProfiles = action.payload
        });
        builder.addCase(businessProfileCreate.fulfilled, (state, action) => {
            state.newBusinessProfile = action.payload
        });
        builder.addCase(businessProfileUpdate.fulfilled, (state, action) => {
            state.businessProfileUpdated = action.payload
        });
        builder.addCase(businessProfileDelete.fulfilled, (state, action) => {
            state.businessProfileDeleted = action.payload
        });
        builder.addCase(businessProfileApprove.fulfilled, (state, action) => {
            state.businessProfileApproved = action.payload
        });
    }
});

export const { 
    businessProfilesGetClear,
    businessProfileCreateClear,
    businessProfileUpdateClear,
    businessProfileDeleteClear,
    businessProfileApproveClear
}  = businessProfilesSlice.actions;
export default businessProfilesSlice.reducer;
