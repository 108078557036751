import { createSlice } from "@reduxjs/toolkit";
import { CaseReducerType } from "../../store";
import { activateProduct } from "../asyncThunks/activateProduct";
import { Paths } from "api/kyc/generated/client2";

type Response = Paths.ActivateProduct.Responses.$200

type State = {
    productActivated: {
		isActive: Response | boolean,
		message?: any
	}
};

const initialState: State = {
    productActivated: {
		isActive: false,
		message: ''
	}
};

type CaseReducers<State> = {
    activateProductClear: CaseReducerType<State, object>;
};

export const productSlice = createSlice<State, CaseReducers<State>, string, {}>({
  name: "productSlice",
  initialState,
  reducers: {
    activateProductClear: (state, { payload }) => {
        state.productActivated = initialState.productActivated
    },
  },
  extraReducers: (builder) => {
    builder.addCase(activateProduct.fulfilled, (state, action) => {
        state.productActivated = {
			isActive: action.payload
		}
    });
    builder.addCase(activateProduct.rejected, (state, action) => {
        state.productActivated = {
			isActive: false,
			message: action.payload
		}
    });
  },
});

export const { activateProductClear }  = productSlice.actions;
export default productSlice.reducer;
