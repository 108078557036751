import React, { FC, useEffect, useState, useRef } from 'react'
import { Button, Upload, Space, Row, Col, Tooltip, App } from 'antd'
import type { UploadFile } from 'antd/es/upload/interface';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import { kycApplicationGet } from 'store/kyc-front/asyncThunks/kycApplicationGet'
import { kycApplicationDocCreate } from 'store/kyc-front/asyncThunks/kycApplicationDocCreate'
import { kycApplicationSectionDocCreate } from 'store/kyc-front/asyncThunks/kycApplicationSectionDocCreate'
import { kycApplicationDocCreateClear, kycApplicationSectionDocCreateClear, kycApplicationSectionDeleteClear, kycApplicationSubSectionDeleteClear } from 'store/kyc-front/slices/kycApplicationSlice'
import { kycApplicationValidate } from 'store/kyc-front/asyncThunks/kycApplicationValidate'
import { themeColor } from '../../../styles/themeStyles'
import { camelCaseToName } from '../../../utilities/nameParsers'
import styled from 'styled-components'
import { useActionStatus } from '../../../hooks/useActionStatus'
import { ButtonUpper } from '../ui-elements/Buttons'
import ApproveInput from "../../kyc-backend/ui-elements/Approve"
import { docGet } from 'store/kyc-front/asyncThunks/docGet'
import { kycApplicationSectionDelete } from 'store/kyc-front/asyncThunks/kycApplicationSectionDelete'
import { kycApplicationSubSectionDelete } from 'store/kyc-front/asyncThunks/kycApplicationSubSectionDelete';
import EditedInputInfo from './EditedInput';
import { kycApplicationChangesGet } from 'store/kyc-front/asyncThunks/kycApplicationChangesGet';
import { docGetClear } from 'store/kyc-front/slices/docsSlice';

const DraggerContainer = styled.div`
    > span:first-child {
        display: flex;
        flex-direction: column-reverse;
    }
    .ant-upload-list {
        border: 1px solid ${themeColor.grayBasic};
        border-top: 0;
        margin-bottom: 30px;
        padding: 0 15px 0 15px;
    }
    .edited .ant-upload-list {
        background-color: ${themeColor.orangeLight};
    }
    .ant-upload-draggable-list-item {
        position: relative;
    }
    .document-edited {
        position: absolute;
        right: 30px;
        top: 15px;
        left: 80%;
        left: calc(100% - 60px);
        &.admin {
            left: 70%;
            left: calc(100% - 137px);
        }
    }
    .deleted {
        color: ${themeColor.grayLight};
        .ant-upload-icon {
            opacity: 0;
        }
        .document-approve {
            display: none;
        }
        .ant-upload-list-item-actions {
            display: none;
        }
    }
    &.empty .ant-upload-list {
        border: 0;
    }
    &.dragger-disabled-body {
        .ant-upload-list-item-name {
            color: ${themeColor.gray}
        }
        .anticon-delete {
            display: none;
        }
    }
    .error {
        .ant-upload-drag {
            border: 1px solid ${themeColor.red};
        }
    }
    .ant-upload-list-item-container {
        .ant-upload-list-item {
            cursor: pointer;
            padding: 15px 0;
            border-bottom: 1px solid ${themeColor.grayBasic};
            margin-top: 0;
            height: initial;
            &:hover {
                color: ${themeColor.primaryColor}
            }
        }
        .ant-upload-list-item-actions {
            width: 115px;
            height: 25px;
            .ant-btn {
                &:first-child {
                    display: none;
                }
            }
        }

        &:last-child {
            .ant-upload-list-item {
                border-bottom: 0;
            }

        }
    }
    .ant-upload-span {
        height: auto;
    }
    .ant-upload-list-item-action {
        position: absolute;
        right: 5px;
        top: 15px;
        &:hover, &:focus, &:active {
            background-color: transparent;
        }
    }
    .ant-upload.error {
        box-shadow: 0 0 2px 1px ${themeColor.red};
    }
    .ant-upload-list-item-name {
        max-width: 60%;
        max-width: calc(100% - 115px);
    }
    .ant-upload-list-text .ant-upload-list-item-card-actions, .ant-upload-list-picture .ant-upload-list-item-card-actions {
        margin-left: 50px;
    }
    .restore-icon {
        position: relative;
        top: -37px;
        left: 80%;
        left: calc(100% - 25px);
        height: 0;
        .anticon {
            cursor: pointer;
        }
    }
    .ant-upload.ant-upload-drag .ant-upload-drag-icon .anticon {
        color: ${themeColor.primaryColor}
    }
    overflow: hidden;
`

const ErrorContainer = styled.div`
    color: ${themeColor.red};
`

const DraggerHeader = styled.h4`
    border: 1px solid ${themeColor.grayBasic};
    border-bottom: 0;
    margin-bottom: -1px;
    text-align: center;
    padding: 15px 20px 0;
    position: relative;
    div {
        padding-bottom: 15px;
        border-bottom: 1px solid ${themeColor.grayBasic};
    }
    &.edited {
        background-color: ${themeColor.orangeLight}
    }

`
const NameDiv = styled.div`
    //display: flex;
    //justify-content: space-between;
    .name-label {
        
    }
    .name-person {
        width: 120px;
    }
    .name-filename {
        width: 140px;
    }
`

interface FProps {
    docs?: any,
    docsGet?: any,
    docsGetParams?: any,
    createdDoc?: any,
    docCreate?: any, 
    docCreateClear?: any,
    docDelete?: any,
    docDeleteClear?: any,
    shouldShowArchived?: boolean,
    docDeleted?: any,
    adminOnly?: boolean,
    docType: string | Blob,
    personId?: string,
    personType?: 'authorized-persons' | 'ubos' | 'individual' | 'company', 
    type: 'ap' | 'ubo' | 'individual' | 'company',
    name: string,
    disabled?: boolean,
    onSwitchChange?: (value: any) => void,
    isErrorMessage?: boolean,
    hideApprove?: boolean,
    uploadAdmin?: boolean,
    nonKycUpload?: boolean,
    maxSize?: number,
    floatingError?: boolean
}

const FormUpload: FC<FProps> = ({docs, docsGet, docsGetParams, docCreate, docCreateClear, createdDoc, docDelete, docDeleteClear, shouldShowArchived, docDeleted, adminOnly, docType, personId, personType, name, disabled, onSwitchChange, isErrorMessage, type, hideApprove, uploadAdmin, nonKycUpload, maxSize, floatingError}) => {
    const [fileEl, setFileEl] = useState();
    const [uploading, setUploading] = useState(false);
    const [listingIsSet, setListing] = useState(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [archivedFileList, setArchivedFileList] = useState<any[]>([]);
    const [deletedFileList, setDeletedFileList] = useState<any[]>([]);
    const [downloadClicked, setDownloadClicked] = useState(false);
    const [gettingApp, setGettingApp] = useState(false)
    const [downloadName, setDownloadName] = useState('');
    const [messageShown, setMessageShown] = useState(true);
    const [showArchived, setShowArchived] = useState(shouldShowArchived ? shouldShowArchived : false);
    const [startedLoading, setStartedLoading] = useState(false);
    const { Dragger } = Upload;
    const envs = useSelector((state) => state.general2.envs);
    const isApUbo = personType === 'authorized-persons' || personType === 'ubos';
    const { isError, isFullfilled } = useActionStatus(docCreate ? docCreate : isApUbo ? kycApplicationSectionDocCreate : kycApplicationDocCreate)
    const { kycApplication, newDoc, newSectionDoc, sectionDeleted, subSectionDeleted } = useSelector((state) => state.user2.kycApplication)
    const { docUrl } = useSelector((state) => state.user2.docs)
    const newDocState = createdDoc ? createdDoc : isApUbo ? newSectionDoc : newDoc;
    const deletedDocState = docDeleted ? docDeleted : isApUbo ? subSectionDeleted : sectionDeleted
    const docCreateAction = docCreate ? docCreate : isApUbo ? kycApplicationSectionDocCreate : kycApplicationDocCreate
    const docCreateClearAction = docCreateClear ? docCreateClear : isApUbo ? kycApplicationSectionDocCreateClear : kycApplicationDocCreateClear
    const docDeleteClearAction = docDeleteClear ? docDeleteClear : isApUbo ? kycApplicationSubSectionDeleteClear : kycApplicationSectionDeleteClear
    const deletingStatus = useActionStatus(docDelete ? docDelete : isApUbo ? kycApplicationSubSectionDelete : kycApplicationSectionDelete);
    const gettingStatus = useActionStatus(docsGet ? docsGet : kycApplicationGet)
    const { applicationChanges } = useSelector((state) => state.user2.kycApplicationChanges)
    const nav = useSelector((state) => state.general2.nav)
    const { message } = App.useApp()
    useEffect(() => {
        setStartedLoading(true);
        return () => {
            setListing(false);
        }
    }, []);              
    useEffect(() => {
        if(messageShown) {
            if(newDocState) {
                if(isFullfilled) {
                    message.success('File was successfully added');
                    if(docsGet) {
                        dispatch(docsGet({ params: docsGetParams }));
                    } else {
                        if(!nonKycUpload) {
                            dispatch(kycApplicationValidate({params: {id: envs.profileId, step: nav.nav.step, showErrors: false}}));
                            dispatch(kycApplicationGet({ params: {id: envs.profileId} }))
                            setGettingApp(true)
                        }
                    }
                    
                    setFileEl(undefined);
                    dispatch(docCreateClearAction({}));
                    setMessageShown(false);
                    setUploading(false)
                }
                
                if(isError) {
                    if(newDocState.status === 413) {
                        message.error(`File size is too large. Try uploading file smaller than ${maxSize ? maxSize : 8}MB`);
                    } else {
                        message.error('Some error occurred while uploading this file')
                    }
                    setFileEl(undefined)
                    dispatch(docCreateClearAction({}))
                    setMessageShown(false)
                    setUploading(false)
                }
                    
            }
            
        }
    }, [newDocState])
    useEffect(() => {
        setStartedLoading(true);
        return () => {
            setListing(false);
        }
    }, []);   
    useEffect(() => {
        if(messageShown) {
            if(deletingStatus.isFullfilled && deletedDocState) {
                message.success('File was successfully deleted')
                if(docsGet) {
                    dispatch(docsGet({ params: docsGetParams }));
                } else {
                    if(!nonKycUpload) {
                        dispatch(kycApplicationValidate({params: {id: envs.profileId}}))
                        dispatch(kycApplicationGet({ params: {id: envs.profileId} }))
                        setGettingApp(true)
                    } 
                }
                dispatch(docDeleteClearAction({}))
                setMessageShown(false);
                setUploading(false);
            }
            if(deletingStatus.isError && deletedDocState) {
                message.error('Some error occurred while deleting this file')
                dispatch(docDeleteClearAction({}))
                setMessageShown(false)
                setUploading(false)
            }
      }
    }, [deletedDocState])
    useEffect(() => {
        if(gettingApp) {
			if(kycApplication.status === 'BEING_CHANGED' || ((kycApplication.status === 'SUBMITTED') && envs.admin)) {
                dispatch(kycApplicationChangesGet({params: {id: envs.profileId}}))
                setGettingApp(false)
                
            }
        }
        
    }, [gettingStatus.isFullfilled])
    useEffect(() => {
            let docsState = docs ? docs : personType === 'authorized-persons' ? kycApplication.values.authorizedPersons.find((el: any) => el.id === personId)?.documents : personType === 'ubos' ? kycApplication.values.beneficialOwners.find((el:any) => el.id ===personId)?.documents : kycApplication.values?.documents ? kycApplication.values?.documents : []
            if(gettingStatus.isFullfilled && gettingStatus.isCleared !== true) {
                let els;
                let archivedEls;
                if(docType === 'any') {
                    kycApplication.values?.authorizedPersons?.map((el: any) => {if(el.documents) {docsState = [...docsState, ...el.documents]}})
                    kycApplication.values?.beneficialOwners?.map((el: any) => {if(el.documents) {docsState = [...docsState, ...el.documents]}})
                    els = docsState?.filter((el: any) => !el.archivedAt);
                    archivedEls = docsState?.filter((el: any) => el.archivedAt);
                } else {
                    els = docsState?.filter((el: any) => el.kind === docType && !el.archivedAt);
                    archivedEls = docsState?.filter((el: any) => el.kind === docType && el.archivedAt);
                }
                let files:any[] = [];
                let archivedFiles:any[] = [];
                if(els) {
                    els.forEach((el: any) => {
                        files.push({
                        uid: el.documentId ? el.documentId : el.id,
                        originalName: el.fileName,
                        name: docType === 'any' ? <NameDiv>
                            <div className='name-label'><b>{camelCaseToName(el.role)}{el.beneficialOwnerId ? ' - ' + el.beneficialOwnerId : el.authorizedPersonId ? ' - ' + el.authorizedPersonId : ''}</b></div>
                            <div className='name-filename'>{el.fileName}</div>
                        </NameDiv> : el.fileName,
                        status: 'done'
                        })
                    });
                }
                if(archivedEls) {
                    archivedEls.forEach((el: any) => {
                        archivedFiles.push({
                        uid: el.documentId ? el.documentId : el.id,
                        originalName: el.fileName,
                        name: docType === 'any' ? <NameDiv>
                            <div className='name-label'><b>{camelCaseToName(el.role)}{el.beneficialOwnerId ? ' - ' + el.beneficialOwnerId : el.authorizedPersonId ? ' - ' + el.authorizedPersonId : ''}</b></div>
                            <div className='name-filename'>{el.fileName}</div>
                        </NameDiv> : el.fileName,
                        status: 'done'
                        })
                    });
                }
                setListing(true);
                setFileList(files);
                setArchivedFileList(archivedFiles)
                setStartedLoading(false)
            }
        
        
        
    }, [kycApplication, personType, docs]);
    useEffect(() => {   
        if(applicationChanges.id) {
            const changesParent = personType === 'authorized-persons' ? applicationChanges.authorizedPersons?.find((person: any) => person.id === personId) : personType === 'ubos' ? applicationChanges.beneficialOwners?.find((person: any) => person.id === personId) : applicationChanges
            const deletedList = changesParent?.documents?.filter((doc: any) => doc.changeType === 'DELETED' && doc.slug === docType)
            let deletedFiles:any[] = [];
            deletedList?.forEach((el: any) => {
                deletedFiles.push({
                    uid: el.documentId ? el.documentId : el.id,
                    originalName: el.previousValue,
                    name: el.previousValue,
                    status: 'done',
                    deleted: true
                })
            });
            setDeletedFileList(deletedFiles)
        }
        
    }, [applicationChanges]);
    useEffect(() => {   
        if(docUrl && downloadClicked) {
            handleDownload(docUrl, docGetClear);
        }
    }, [docUrl]);

    const handleDownload = (docUrlResp: any, clearAction: any) => {
        let val = docUrlResp;
        if(val && val !== '') {
            const url = window.URL.createObjectURL(new Blob([val]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadName);
            document.body.appendChild(link);
            link.click();
            dispatch(clearAction({}));
            setDownloadClicked(false);
            setDownloadName('');
        }
    }
   
    const handleUpload = (file: any) => {
        setUploading(true);
        setMessageShown(true);
        const formData = new FormData();
        formData.append('file', file.file);
        formData.append('kind', docType);
        if(adminOnly) {
            /*@ts-ignore*/
            formData.append('adminOnly', adminOnly);
        }
        if(isApUbo) {
            dispatch(docCreateAction({ params: {id: envs.profileId, sectionName: personType, sectionId: personId }, data: formData} ))
        } else {
            dispatch(docCreateAction({ params: {id: envs.profileId}, data: formData} ))
        }
    };
    const handleDelete = (file: any) => {
    };
    const handleShowArchived = () => {
        setShowArchived(!showArchived);
    }
    /*const handleRestoreClick = (el: any) => {
        setRestore(true);
        setUploading(true);
        dispatch(docDelete({ params: {...docsGetParams, documentId: el.uid}, data: {archive: false} }))
        setMessageShown(true);
        setListing(false);
    }*/
    const uploadProps = {
        multiple: true,
        showUploadList: {
            showDeleteIcon: true,
            showPreviewIcon: true,
            showDownloadIcon: true,
            downloadIcon: <span style={{'position': 'absolute', 'top': '0', 'right': '20px', 'width': '100%', 'height': '100%'}}></span>
            
        },
        onRemove: (file: any) => {
            setUploading(true);
            if(docDelete) {
                dispatch(docDelete({params: {id: envs.clientId, documentId: file.uid}}))
            } else {
                if(isApUbo) {
                    if(personId) {
                        dispatch(kycApplicationSubSectionDelete({ params: {applicationId: envs.profileId, sectionName: personType, sectionId: personId, documentId: file.uid}}))
                    }
                } else {
                    dispatch(kycApplicationSectionDelete({ params: {applicationId: envs.profileId, sectionName: 'documents', sectionId: file.uid} }))
                }
            }
            setMessageShown(true);
            setListing(false);
            return false
        },
        onDownload: (file: any) => {
            setDownloadClicked(true);
            setDownloadName(file.originalName)
            dispatch(docGet({ params: {id: file.uid}, config: {responseType: 'blob'} }))
        },
        beforeUpload: (file: any) => {
            return false;
        },
        onDrop: (file: any) => {
            //console.log(file);
        },
        onChange: (file: any) => {
            setListing(false);
            if(file.file.status !== 'removed') {
                handleUpload(file)
            } else {
                handleDelete(file)
            }
            
        },
        fileEl
    };
    let disabledEl = disabled;
    const edited =  false
	return (
        <>
            <>  
                
                {envs.admin &&
                    <label className='ant-form-item-label' style={{'width': '100%'}}>
                        <Row justify="space-between">
                            <Col></Col>
                            {/*<ApproveInput onSwitchChange={onSwitchChange} name={'documentId'} type='document' schemaParent={schemaParent} schemaName={schemaName} />*/}
                        </Row>
                    </label>
                }
                {fileList?.length ? (
                    <DraggerHeader className={edited ? 'edited dragger-header' : 'dragger-header'}><div>
                        {name}
                        {edited &&
                            <EditedInputInfo name={name} type={type} isDocument={true} />
                        }
                    </div></DraggerHeader>
                ) : (<></>)}
                <DraggerContainer className={fileList?.length ? 'dragger-body' : 'empty error dragger-body'}>
                    <Dragger 
                        className={isErrorMessage ? 'error' : edited ? 'edited' : ''} 
                        {...uploadProps}
                        id='firstPage'
                        disabled={disabledEl} 
                        fileList={[...fileList, ...deletedFileList]}
                        style={{'display' : envs.admin && !uploadAdmin ? 'none' : 'initial'}}
                        itemRender={(originNode, file, currFileList, actions) => (
                            <DragableUploadListItem
                                originNode={originNode}
                                actions={actions}
                                file={file}
                                fileList={currFileList}
                                personId={personId}
                                onSwitchChange={onSwitchChange}
                                nonKycUpload={nonKycUpload}
                                type={type}
                                hideApprove={hideApprove}
                                docType={docType}
                            />
                        )}
                    >
                        <Space direction='vertical' size={15} >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text"><b>{name}</b></p>
                            <p className="ant-upload-hint">
                                Click or drag file to this area to upload<br />
                                <span>Max file size is {maxSize ? maxSize : 8}MB</span>
                            </p>
                            <Button icon={<UploadOutlined />} loading={uploading} style={{'marginTop': '15px'}} disabled={disabledEl}>Click to upload</Button>
                        </Space>
                    </Dragger>
                    <ErrorContainer style={floatingError ? {'marginBottom': '15px', 'marginTop' : '3px'} : {'marginTop' : '3px'}}>
                        {isErrorMessage ? (
                            <div style={floatingError ? {'position' : 'absolute'} :  {}} >At least one document should be uploaded</div>
                        ) : null}
                    </ErrorContainer>
                </DraggerContainer>
                {archivedFileList?.length && envs.admin ? (
                    <Row>
                        <Col span={24} style={{'textAlign': 'right', 'marginTop': '15px'}}>
                            <ButtonUpper btnType='dashed' className='btn-sm dashed-primary right' ghost onClick={handleShowArchived}>{showArchived ? 'Hide archived documents' : 'Show archived documents'}</ButtonUpper>
                        </Col>
                    </Row>
                ):(<></>)}
                {showArchived && envs.admin &&
                    <>
                        {archivedFileList?.length ? (
                            <div style={{marginTop: '30px'}}>
                                <DraggerHeader className='dragger-header dragger-disabled-header'><div>Archived {name}</div></DraggerHeader>
                            </div>
                        ) : (<></>)}
                        <DraggerContainer className={archivedFileList?.length ? 'dragger-body dragger-disabled-body' : 'empty error dragger-body dragger-disabled-body'}>
                            <Dragger 
                                className={isErrorMessage ? 'error' : ''} 
                                {...uploadProps} 
                                //showUploadList={{showRemoveIcon: true, removeIcon: <span className='aaa bbb'></span>}}
                                id='archivedPage' 
                                disabled={disabledEl} 
                                fileList={archivedFileList} 
                                style={{'display' : envs.admin ? 'none' : 'initial'}}
                                itemRender={(originNode, file, currFileList, actions) => (
                                  <DragableUploadListItem
                                    //handleRestoreClick={() => handleRestoreClick(file)}
                                    originNode={originNode}
                                    actions={actions}
                                    archived={true}
                                    file={file}
                                    fileList={currFileList}
                                    personId={personId}
                                    onSwitchChange={onSwitchChange}
                                    nonKycUpload={nonKycUpload}
                                    type={type}
                                    hideApprove={true}
                                    docType={docType}
                                  />
                                )}
                                
                            >
                            </Dragger>
                        </DraggerContainer>
                    </>
                }
            </>
        </>
    )
}

const type = 'DragableUploadList';

interface ExtendedUploadFile extends UploadFile {
    deleted?: boolean;
}

interface DragableUploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: ExtendedUploadFile;
  archived?: boolean;
  actions: { download: () => void };
  type: 'ap' | 'ubo' | 'company' | 'individual'
  fileList: ExtendedUploadFile[],
  personId?: string,
  docType?: string | Blob,
  hideApprove?: boolean,
  nonKycUpload?: boolean,
  onSwitchChange: ((value: any) => void) | undefined,
  //handleRestoreClick?: ((value: any) => void) | undefined
}

const DragableUploadListItem = ({originNode, file, archived, fileList, actions, personId, type, docType, hideApprove, nonKycUpload, onSwitchChange}: DragableUploadListItemProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const index = fileList.indexOf(file)
  const envs = useSelector((state) => state.general2.envs)
  const { clientSummary } = useSelector((state) => state.admin2.clients);
  
  //const isDisabled = nonKycUpload ? clientSummary[0] ? clientSummary[0].status !== 'PENDING_KYC' : false : undefined
  const isDisabled = nonKycUpload ? false : undefined
  const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  const download = (el: any) => {
    if(el.target.dataset.icon === 'delete' || el.target.classList.contains('ant-upload-list-item-action')) {
        return
    }
    actions.download()
  }
  return (
    <div
      ref={ref}
      className={file.deleted ? `ant-upload-draggable-list-item deleted` : `ant-upload-draggable-list-item`}
      style={{ cursor: 'move' }}
    >
        <span onClick={(el) => download(el)}>{file.status === 'error' ? errorNode : originNode}</span>
        {envs.admin && docType !== 'any' && !hideApprove && <ApproveInput name={nonKycUpload ? file.uid : 'documents.' + file.uid} isDocument={true} nonKycApprove={nonKycUpload} isDisabled={isDisabled} type={type} personId={personId} />}
        {docType &&
            <EditedInputInfo name={'' + docType} isDocument={true} documentName={file.name} type={type} />
        }
        {/*{envs.admin && archived && docType !== 'any' && <div className='restore-icon'><ArrowUpOutlined title='Restore file' onClick={handleRestoreClick} /></div>}*/}
    </div>
  );
};

export default FormUpload
