import { Col, Divider, Row, Statistic } from 'antd'
import Text from 'antd/es/typography/Text'
import Title from 'antd/es/typography/Title'
import { countries } from 'countries-list'
import _ from 'lodash'
import { FC, useEffect, useState } from 'react'
import { kycApplicationGet } from 'store/kyc-front/asyncThunks/kycApplicationGet'
import styled from 'styled-components'
import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import { themeColor } from '../../../styles/themeStyles'
import KycLayout, { NavItem } from '../../kyc-front/kyc-layout/KycLayout'
import FormUpload from '../../kyc-front/ui-elements/FormUpload'

const SubText = styled(Text)`
	font-size: 14px;
`
const DividerCustom = styled(Divider)`
	margin: 0;
`
const StatisticCustom = styled(Statistic)`
	margin-top: 25px;
	.ant-statistic-title {
		color: ${themeColor.grayDark};
	}
	.ant-statistic-content-value {
		font-size: 16px;
	}
`
const Summary = styled.p`
	display: flex;
	span {
		width: 120px;
		min-width: 120px;
		display: inline-block;
		&.value {
			width: auto;
			display: inline-block;
		}
	}
`


const KycIntro: FC = () => {
	
	const { kycApplication } = useSelector((state) => state.user2.kycApplication)
	const values = kycApplication.values
	const envs = useSelector((state) => state.general2.envs)
	const [detailsLoading, setDetailsLoading] = useState(false)
  	const status = kycApplication.status
	useEffect(() => {
  		if(envs.accountType === '' || envs.profileId === '') {
  			setDetailsLoading(true);
  		} else {
  			getDetails();
  		}
    }, []);
    useEffect(() => {
    	if(detailsLoading && envs.accountType !== '' && envs.profileId !== '') {
    		getDetails();
    	}
    }, [envs.accountType, envs.profileId]);
    const getDetails = () => {
		//dispatch(kycApplicationGet({params: {id: envs.profileId}}))
		//dispatch(clientGet({params: {id: envs.profileId}}))
    }
	let archive = true
	return (
		<KycLayout nav={{next: "/step1", step: 0, nextName: status === 'REJECTED' ? "View KYC" : "Review KYC", nextButton: "primary", status: status, navSubpage: 'kyc', navPage: NavItem.CLIENT_PROFILES }}>
			<Row gutter={30}>
				<Col span={24} style={{'marginBottom': '20px', 'marginTop': '-16px'}}>
					<Text style={{'fontSize': '25px', 'fontWeight':'bold'}}>KYC Status:
						<Text type={status === 'SUBMITTED' || status === 'ACCEPTED' ? "success" : status === 'NEW' ? "secondary" : "secondary"}><b> { status === 'CLARIFICATION_NEEDED' ? 'Clarification Needed' : status === 'BEING_CHANGED' ? 'Being Changed' : status}</b></Text>
					</Text>
				</Col>
				<DividerCustom />
				{envs.isBusiness ? (
					<Col span={12}>
						<Title level={4} style={{'marginBottom': '20px', 'marginTop': '15px'}}>Company Info</Title>
						<Summary><span>Company:</span> <b>{values.name}</b></Summary>
						{/*@ts-ignore*/}
						<Summary><span>Country:</span> <b>{countries[values.registeredAddress?.country]?.name}</b></Summary>
						<Summary><span>Website:</span> <b>{values?.website}</b></Summary>
					</Col>
				) : (
					<Col span={12}>
						<Title level={4} style={{'marginBottom': '20px', 'marginTop': '15px'}}>User Info</Title>
						<Summary><span>Name:</span> <b>{values.firstName ? values.firstName : 'New'} {values.lastName ? values.lastName : 'Person'}</b></Summary>
						<Summary><span>Email:</span> <b>{values?.email}</b></Summary>
						<Summary><span>Phone:</span> <b>{values?.phone}</b></Summary>
					</Col>
				)}
	        </Row>
			{envs.isBusiness &&
				<>
					<DividerCustom style={{marginBottom: 15}} />
					<Row gutter={30}>
						<Col span={24}><Title level={4}>Company documents</Title></Col>
						<Col span={24}>
							<FormUpload name='All documents' disabled={true} docType='any' personId={envs.profileId} type='ap' />
						</Col>
					</Row>
					<DividerCustom style={{marginBottom: 15}} />
					{!_.isEmpty(values) &&
						<Row gutter={30}>
							<Col span={24}><Title level={4}>Contact persons</Title></Col>
							{values && values.authorizedPersons && values.authorizedPersons.map((person: any) => <ContactModule persons={person} />)}
						</Row>	
					}
				</>
			}	
			{envs.isPrivate &&
				<>
					<DividerCustom style={{marginBottom: 15}} />
					<Row gutter={30}>
						<Col span={24}><Title level={4}>Individual documents</Title></Col>
						<Col span={24}>
							<FormUpload name='All documents' disabled={true} docType='any' personId={envs.profileId} type='ap' />
						</Col>
					</Row>
				</>
			}		
		</KycLayout>
			 
	)
}

const ContactModule = (person: any) => {
	let personEl = person.persons;
	if(personEl === undefined || personEl?.archivedAt || personEl?.referencedAuthorizedPerson) {
		return <></>
	}
	let fullName = personEl.name;
	let firstName = personEl.firstName ? personEl.firstName : 'New'
	let lastName = personEl.lastName ? personEl.lastName : 'Person'
	return (
		<Col span={8}>
			<StatisticCustom title={personEl.beneficialOwnerId ? 'UBO' : personEl.roleType ? personEl.roleType === 'OtherIndividual' ? 'Authorized Person' : personEl.roleType : 'Contact Person'} value={fullName ? fullName : firstName + ' ' + lastName} />
			<br />
			{personEl.email && <><SubText>{personEl.email}</SubText><br /></>}
			{personEl.phone && <><SubText>{personEl.phone}</SubText><br /></>}
		</Col>
	)
}

export default KycIntro
