import { Spin } from 'antd'
import { FC, useEffect, useState } from 'react'
import { dispatch } from 'store/store'
import { useSelector } from 'hooks/useSelector'
import { Step } from 'stepsScheme'
import { kycApplicationGet } from 'store/kyc-front/asyncThunks/kycApplicationGet'
import { kycApplicationValidate } from 'store/kyc-front/asyncThunks/kycApplicationValidate'
import KycLayout, { NavItem } from 'modules/kyc-front/kyc-layout/KycLayout'
import KycFormInner from 'modules/kyc-front/forms/KycFormInner'
import { kycApplicationUpdate } from 'store/kyc-front/asyncThunks/kycApplicationUpdate'
import { kycApplicationSectionUpdate } from 'store/kyc-front/asyncThunks/kycApplicationSectionUpdate'
import { kycApplicationUpdateClear, kycApplicationSectionUpdateClear } from 'store/kyc-front/slices/kycApplicationSlice'
import { kycApplicationChangesGet } from 'store/kyc-front/asyncThunks/kycApplicationChangesGet'
import { useActionStatus } from 'hooks/useActionStatus'


export interface FProps {
    step: number; 
    name: string; 
	substep?: number;
	hasSubsteps?: boolean;
    link: string; 
    fields: string[]; 
	steps?: Step[]
}

interface FormProps {
	index: number;
    form: FProps;
    prev?: FProps;
    next?: FProps;
	isSection?: boolean;
	type?: string;
	refId?: string;
  }

const KycForm: FC<FormProps> = ({index, form, prev, next, isSection, type, refId}) => {	
    const {step, substep, name, fields, link} = form;
	const kycApplication = useSelector((state) => state.user2.kycApplication);
	const id = kycApplication.kycApplication.id;
	const envs = useSelector((state) => state.general2.envs);
	const { stepsScheme } = kycApplication
	const changesActionStatus = useActionStatus(kycApplicationChangesGet)
	const isLastStep = step === stepsScheme.slice(-1)[0].step
	const isDocStep = fields[0] === 'docs.OtherDocumentKind'
	const hideValidate = type === 'authorizedPersons' || type === 'beneficialOwners' || isDocStep
	const hideSaveButton = step < 1 || isDocStep || isLastStep || hideValidate
	const hideApproveStepButton = step < 1 || type === 'authorizedPersons' || type === 'beneficialOwners' || (isLastStep && envs.isPrivate)
	let validationActionParams: any = {id: envs.profileId, step: step}
	const [errorsListing, setErrorsListing] = useState(false)
	if(substep) {
		validationActionParams['substep'] = substep
	}
	if(refId) {
		validationActionParams['refId'] = refId
	}
	return (
      	<KycLayout nav={{step: step, substep: substep, refId: refId, next: next?.link, prev: step === 1 ? envs.admin ? '/summary' : `/` : prev?.hasSubsteps ? stepsScheme[index - 2].link :  prev?.link, hideNav: isLastStep && !envs.admin, link: link}} hideValidate={hideValidate} hideSaveButton={hideSaveButton} hideApproveStepButton={hideApproveStepButton} validationAction={kycApplicationValidate} validationActionParams={validationActionParams} approve={isLastStep && envs.admin} setErrorsListing={setErrorsListing}>
      		{id && id !== '' ? (
				<KycFormInner 
					errorsListing={errorsListing}
					isSection={isSection}
                    fields={fields}
					applicationId={id} 
					formGet={kycApplicationGet} 
					formUpdate={isSection ? kycApplicationSectionUpdate : kycApplicationUpdate} 
					formUpdateClear={isSection ? kycApplicationSectionUpdateClear : kycApplicationUpdateClear} 
    				validationAction={kycApplicationValidate} 
				/>
			) : (
				<Spin size="large" />
			)}
		</KycLayout>
			 
	)
}

export default KycForm
