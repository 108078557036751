import { useSelector } from 'hooks/useSelector'

/**
 * check if token exist
 * when isLogoutPending, always return true to prevent redirection to the login page
 */

type IReturn = {
	isUserLogged: boolean
}

export const useIsUserLogged = (): IReturn => {
	const auth = useSelector((state) => state.general2.auth)
	const pathname = window.location.href;
	let isLoginEnvCorrect = pathname.includes('admin') ? auth.loginEnv === 'backoffice' : auth.loginEnv === 'frontoffice'; 
	return {
		isUserLogged: !!(auth.session && auth.session.access_token && isLoginEnvCorrect),
	}
}
