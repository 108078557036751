import { createAsyncThunk } from "@reduxjs/toolkit";
import { AsyncThunkActionType } from "../../store";
import { AsyncThunkDataType } from "../../common";
import { api2 } from "api/kyc/api";
import { Components, Paths } from "api/kyc/generated/client2";
import { NotesResponse } from "../slices/notesSlice";

type RequestBody = Paths.CreateNote.RequestBody

export const noteCreate: AsyncThunkActionType<
  string,
  AsyncThunkDataType<null, RequestBody, "params">
> = createAsyncThunk("noteCreate", async (data, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const response = await api2.createNote(null, data?.data, data?.config);
    return response.data as string
  } catch (err: any) {
    return rejectWithValue(err.response.data);
  }
});
