import { GlobalOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { lang, SupportedLang } from 'utilities/i18n/i18n'

export const LanguagePicker: FC = () => {
	const { resolvedLanguage, changeLanguage } = useTranslation().i18n
	
	return (
		<span style={{color: '#000'}}>
				<Dropdown
					menu={{
						items: Object.keys(lang).length > 1 ? Object.keys(lang).map((key) => ({
							key,
							label: <span onClick={()=>changeLanguage(key)}>{lang[key as SupportedLang].native}</span>,
					})) : [] }}
				>
					<span>
						<GlobalOutlined style={{ marginRight: 5 }} />
						{lang[resolvedLanguage as SupportedLang || ''].native}
					</span>
				</Dropdown>
		</span>
	)
}
