import { useSelector } from '../../../hooks/useSelector'
import { dispatch } from '../../../store/store'
import { FC, useEffect} from 'react'
import styled from 'styled-components'
import { themeColor } from '../../../styles/themeStyles'
import KycLayout, { NavItem } from '../kyc-layout/KycLayout'
import { MessagesListing } from '../ui-elements/Comment'
import { messagesGet } from 'store/kyc-front/asyncThunks/messagesGet'

const CommentCustom = styled.div`
	margin-top: -40px;
	.ant-list-header {
		text-align: right;
		color: ${themeColor.gray}
	}
	.ant-list-items {
		.ant-comment {
	        border-bottom: 1px solid ${themeColor.grayWhite};
	        &:last-child{
	        	border: none;
	        }
	    }
	}
    
`

const KycInbox: FC = () => {
  	const envs = useSelector((state) => state.general2.envs);
  	const messages = useSelector((state) => state.user2.messages);
	useEffect(() => {
		dispatch(messagesGet({params: {clientId: envs.clientId}}))
  	}, []);
	return (
		<KycLayout nav={{prev: envs.admin ? '/summary' : `/`, step: -1, prevName: "Back to KYC", navPage: NavItem.CLIENT_INBOX, hideSteps: true}}>
			<>
				<CommentCustom>
                    <MessagesListing 
                        type='message' 
                        side='client'
                        listing={messages.profileMessages}
                     />
				</CommentCustom>
			</>
		</KycLayout>
			 
	)
}

export default KycInbox
