import { useNavigate, useParams } from 'react-router-dom'
import { App, Col, Row } from 'antd'
import { dispatch } from '../../../../store/store'
import { useSelector } from '../../../../hooks/useSelector'
import { FC, useEffect, useState } from 'react'
import { useActionStatus } from '../../../../hooks/useActionStatus'
import KycLayout, { NavItem } from 'modules/kyc-front/kyc-layout/KycLayout'
import { riskProfilesGet } from 'store/kyc-backend/asyncThunks/riskProfilesGet'
import { riskProfileCreate } from 'store/kyc-backend/asyncThunks/riskProfileCreate'
import RiskProfile from './RiskProfileLayout'
import { riskProfilesGetClear, RiskProfileType } from 'store/kyc-backend/slices/riskSlice'
import { ErrorIcon } from 'modules/kyc-front/ui-elements/SuccessInfo'
import styled from 'styled-components'

interface RPContainerProps {
    hasId?: boolean,
}

const CenteredCol = styled(Col)`
	text-align: center;
	margin-bottom: 0;
	margin-top: 40px;
`

export const findDefaultProfile = (riskProfiles: RiskProfileType[]) => {
    const sortedProfiles = riskProfiles.slice().sort((a: RiskProfileType, b: RiskProfileType) => {
        const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0
        const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0
        return dateB - dateA
    })
    const approvedProfile = sortedProfiles.find((elem: any) => elem?.status === 'APPROVED');
    return approvedProfile ? approvedProfile : sortedProfiles[0]; 
}

const RiskProfileContainer: FC<RPContainerProps> = ({hasId}) => {
	const navigate = useNavigate();
	const envs = useSelector((state) => state.general2.envs);
	const { riskProfiles } = useSelector((state) => state.admin2.riskProfiles);
    const { riskProfileId } = useParams()
	const [dataLoaded, setDataLoaded] = useState(false);
    const riskProfilesGettingStatus = useActionStatus(riskProfilesGet)
    const { message } = App.useApp()
	useEffect(() => {
		callInitialFunctions();
    }, [envs.profileId]);
    useEffect(() => {
        if(riskProfilesGettingStatus.isError) {
            message.error('Some error occured while getting this element')
            dispatch(riskProfilesGetClear({}))
        }
        if(riskProfilesGettingStatus.isFullfilled && dataLoaded === false) {
            if(hasId) {
                const bProfile = riskProfiles.find((elem: any) => elem?.id === riskProfileId);
            } else {
                const defaultProfile = findDefaultProfile(riskProfiles)
                if(defaultProfile?.id) {
                    navigate(envs.routeSuffix + '/' + NavItem.CLIENT_PROFILES + '/' + envs.profileId + '/risk-profile/' + defaultProfile.id);
                }
            }
            setDataLoaded(true);
        }
    }, [riskProfilesGettingStatus])
    const callInitialFunctions = () => {
    	if(dataLoaded === false && envs.profileId !== '') {
            dispatch(riskProfilesGet({params: {clientId: envs.profileId}}))
		}
    }
	return (dataLoaded ? <RiskProfile riskProfileId={riskProfileId} /> : riskProfilesGettingStatus.isError ? (
        <>
            <KycLayout nav={{hideNav: true, hideSteps: true, navSubpage: 'risk-profile', navPage: NavItem.CLIENT_PROFILES}}>
                <div>
                    <Row justify='center'>
                        <CenteredCol>
                            <ErrorIcon />
                            <p><b>Error</b></p>
                            <p>Some error ocurred while loading this page</p>
                        </CenteredCol>
                    </Row>
                </div>
                
            </KycLayout>
        </>) : (<></>)
    );
}

export default RiskProfileContainer
