import { useSelector } from 'hooks/useSelector'
import { useActionStatus } from 'hooks/useActionStatus'
import { setEnvs } from 'store/kyc-front/slices/envsSlice'
import { FC, ReactNode, useEffect } from 'react'
import { CoverPage } from 'modules/kyc-front/ui-elements/CoverPage'
import { Navigate, useParams } from 'react-router-dom'
import { dispatch } from 'store/store'
import { NavItem } from 'modules/kyc-front/kyc-layout/KycLayout'
import { clientGet } from 'store/kyc-backend/asyncThunks/clientGet'

const RedirectIfNoUser: FC<{ children?: ReactNode }> = ({ children }) => {
    let { id } = useParams()
    const { kycApplication } = useSelector((state) => state.user2.kycApplication)
    const envs = useSelector((state) => state.general2.envs);
    const { apiURL, env, type, routeSuffix, adminSuffix } = envs
    const { clientSummary } = useSelector((state) => state.admin2.clients)
    const client = clientSummary.length ? clientSummary[0] : undefined
    const gettingClientStatus = useActionStatus(clientGet)
    useEffect(() => {
		if((envs.profileId == '' || client?.profileId == '' || !client) && !gettingClientStatus.isPending && !gettingClientStatus.isFullfilled) {
			if(id) {
                dispatch(clientGet({params: {id: id}}))
            }
		}
	}, [id]);
    useEffect(() => {
		if(client?.profileId !== '' && envs.profileId === '' || kycApplication.clientId && kycApplication.clientId !== envs.clientId && kycApplication.clientId !== '') {
			dispatch(setEnvs({apiURL, admin: true, adminSuffix: adminSuffix,  type: type, env: env, routeSuffix: routeSuffix, profileId: client?.profileId ? client.profileId : '', clientId: kycApplication.clientId, accountType: client?.accountType === 'COMPANY' ? 'BUSINESS' : 'PRIVATE'}));
		}
	}, [client, kycApplication.clientId]);

    if((client?.profileId == '' && gettingClientStatus.isFullfilled) || gettingClientStatus.isError) {
        return <Navigate to={routeSuffix + '/' +  NavItem.CLIENT_PROFILES} replace />
    } else {
        if (!gettingClientStatus.isFullfilled && !client) {
            return <CoverPage visible={true} />
        }
        return <>{children}</>
    }
	
}
export const UserAdminRoute: FC<{ dynamic?: boolean, children: ReactNode }> = ({ children }) => {
    return (
		<RedirectIfNoUser>
			{children}
		</RedirectIfNoUser>
	)
}
