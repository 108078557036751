import { useSelector } from 'hooks/useSelector'
import { noop } from 'lodash'
import { getBasePath } from 'modules/keycloak/api/keycloakActions'
import { SessionResponse } from 'modules/keycloak/api/types'
import { getToken } from 'modules/keycloak/store/getToken'
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { dispatch, persistor } from 'store/store'
import { setBackToUri } from '../store/authSlice'
import { useIsUserLogged } from './useIsUserLogged'

interface IProps {
	loginPagePath: string
	homePath: string
	keycloakDomain?: string
	onLoginSuccess?: (res: SessionResponse) => void,
	client_id: string,
	is_backend: boolean
}

/**
 * Create login component and run it there
 *
 * @param homePath - home app path url to redirect after login success (if backToUri not exist)
 * @param loginPagePath - path url where is used this hook
 * @param keycloakDomain
 * @param onLoginSuccess
 */
export const useKeycloakLoginPage = ({ loginPagePath, keycloakDomain, homePath, onLoginSuccess = noop, client_id, is_backend }: IProps): void => {
	const navigate = useNavigate()
	const location = useLocation();
	const { isUserLogged } = useIsUserLogged()
	const { backToUri, lastAuthCode } = useSelector((state) => state.general2.auth)
	const query = new URLSearchParams(location.search)
	const code = query.get('code')

	const redirectToAuth = useCallback(() => {
		if (keycloakDomain) {
			persistor.flush().then(() => {
				window.location.href = keycloakDomain + `${getBasePath(is_backend)}/auth?response_type=code&client_id=${client_id}&redirect_uri=` + loginPagePath
			})
		}
	}, [keycloakDomain, loginPagePath])
	
	// avoid fetching token twice (eg: in strict react mode)
	let isTokenFetching= useRef<boolean>(false)
	/**
	 * read session code and getToken
	 * redirect to keycloak otherwise
	 */
	useEffect(() => {
		// auth code has to be uniq (usable only once)
		if (!isUserLogged && keycloakDomain && !isTokenFetching.current) {
			if (code && code !== lastAuthCode) {
				isTokenFetching.current = true
				dispatch(getToken({ params: { keycloakDomain, code, redirect_uri: loginPagePath, client_id, is_backend } }))
					.unwrap()
					.then((res) => onLoginSuccess(res.data))
					.catch(redirectToAuth)
			} else {
				// prevent redirection during logging process
				if (!isUserLogged) {
					redirectToAuth()
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code]) // only code

	/**
	 * getToken success - redirect to user page
	 */
	useEffect(() => {
		if (isUserLogged) {
			navigate(backToUri || homePath, { replace: true })
			setTimeout(() => dispatch(setBackToUri('')), 1000) // timeout to prevent refresh Login component
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUserLogged]) // only isUserLogged
}
